import {Navigate, useParams, useRoutes} from 'react-router-dom';
// auth
import { io } from 'socket.io-client';
import VerPerfilPage from 'src/pages/VerPerfilPage';
import EsqueciSenhaPage from 'src/pages/EsqueciSenhaPage';


import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';

//
import {
  Page404,
  PageTwo,
  PageSix,
  PageFour,
  PageFive,
  LoginPage,
  PageThree,
  ChatPage,
  ConversasPage
} from './elements';
import ChatVideo from "../pages/ChatVideo";
import ChatAudio from "../pages/ChatAudio";
import AtendetesPage from "../pages/AtendetesPage";
import PageDashfirst from "../pages/PageDashfirst";
import PageSalaEspera from "../pages/PageSalaEspera";
import PagePagamento from "../pages/PagePagamento";
import AtendimentoPage from "../pages/AtendimentoPage";
import AgendamentosPage from "../pages/AgendamentosPage";

import FinanceiroPage from "../pages/FinanceiroPage";
import Avaliacoes from "../sections/@dashboard/Avaliacoes/Avaliacoes";
import CadastrePage from "../pages/CadastrePage";
import PersonalizarPerfilPage from "../pages/PersonalizarPerfilPage";
import ConfimarCadastroPage from "../pages/ConfimarCadastroPage";
import EditarPerfilPage from '../pages/EditarPerfilPage';
import CadastroGooglePage from "../pages/CadastroGooglePage";
import AgendamentoPage from "../pages/AgendamentoPage";
import ConversarPage from "../pages/ConversarPage";
import CadastregoogleformPage from "../pages/CadastregoogleformPage";
import ReagendamentoPage from "../pages/ReagendamentoPage";




// ---------------------------------------------------------------------- https://socket.eyhe.com.br:3001/chat
const socket = io('https://socket.eyhe.com.br:3001/chat');

// client-side
socket.on("connect", () => {
  console.log(socket.id); // x8WIv7-mJelg7on_ALbx
});// client-side
document.addEventListener("visibilitychange", () => {
  const Agent = navigator.userAgent;
  if (Agent.includes("iPhone") || Agent.includes("iPad") || Agent.includes("iPod")) {
    if (document.hidden) {
      socket.disconnect();
    } else {
      socket.connect();
    }
  }
});

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'cadastro', element: <CadastrePage /> },
        { path: 'cadastrar/:tipoAtendente', element: <CadastrePage /> },
         { path: 'esquecisenha', element: <EsqueciSenhaPage /> },
        { path: 'confirmarcadastro', element: <ConfimarCadastroPage /> },
        { path: 'confirmarcadastroform', element: <CadastregoogleformPage /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout socket={socket} />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashfirst', element: <PageDashfirst /> },
        { path: 'atendimentos', element: <AtendimentoPage /> },
        { path: 'agendamentos', element: <AgendamentosPage /> },
        { path: 'financeiro', element: <FinanceiroPage /> },
        { path: 'atendentes', element: <AtendetesPage /> },
        { path: 'avaliacoes', element: <Avaliacoes /> },

        { path: 'salaespera/:tokenConversa', element: <PageSalaEspera socket={socket}/> },
        { path: 'pagamento/:idAtendente', element: <PagePagamento/> },
        { path: 'notifica', element: <PageFour /> },
        { path: 'personalizarperfil', element: <PersonalizarPerfilPage /> },

        { path: 'verperfil/:idAtendente', element: <VerPerfilPage /> },
        { path: 'editarperfil', element: <EditarPerfilPage /> },
        { path: 'agendamento/:idAtendente', element: <AgendamentoPage /> },
        { path: 'reagendamento/:idAtendente/:idAgendamento', element: <ReagendamentoPage /> },
        { path: 'conversar/:idAtendente', element: <ConversarPage /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: 'four', element: <PageFour /> },
            { path: 'five', element: <PageFive /> },
            { path: 'six', element: <PageSix /> },
          ],
        },

        {
          path: 'chat',
          children: [
            { element: <ChatPage socket={socket} />, index: true },
            { path: ':tokenConversa', element: <ChatPage socket={socket} /> },
          ],
        },
        {
          path: 'video',
          children: [
            { path: ':tokenConversa', element: <ChatVideo socket={socket} /> },
          ],
        },
        {
          path: 'audio',
          children: [
            { path: ':tokenConversa', element: <ChatAudio socket={socket} /> },
          ],
        },

      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
