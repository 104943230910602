import {Divider, IconButton,Button, List, MenuItem, Paper, Box, Grid,FormControl,OutlinedInput,InputAdornment, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import {useSettingsContext} from "../settings";
import {useAuthContext} from "../../auth/useAuthContext";
import Iconify from "../iconify";
import MenuPopover from "../menu-popover";

import BadgeStatus, {BadgeStatusValue} from "../badge-status";

import AtendentesHistoricoAcolhimentoRecorrente from "./AtendentesHistoricoAcolhimentoRecorrente";
import './HistoricoAcolhimentoRecorrente.css';

import fotoAtendimento from './img-historico-atendimento.jpg';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

type props = {
    conversas: any,
    filtrar: any
}

  export default function HistoricoAcolhimentoRecorrente({conversas, filtrar}:props){

      const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
          try {

              filtrar(event.target.value);

          } catch (error) {
              console.error(error);
          }
      };

    return (
        <>

        
            <div className="CardHistoricoAcolhimentoRecorrente">
                <div className="HeaderHistoricoAcolhimentoRecorrente">
                    <Typography>Histórico de acolhimentos</Typography>
                </div>
                <div className="InputSearchHistoricoAcolhimentoRecorrente">
                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                        <OutlinedInput
                            id="InputBuscaporAnjoeFiltro"
                            className="InputBuscaporAnjoeFiltro"
                            startAdornment={<InputAdornment position="start"><Iconify icon="eva:search-outline"/></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            onChange={handleChangeSearch}
                            inputProps={{
                            'aria-label': 'weight',
                            }}
                        />
                    </FormControl>
                </div>
                
                <Divider/>

                <div className="MainHistoricoAcolhimentoRecorrente">
                    {conversas.map((conversa:any,index:any) => (
                    <AtendentesHistoricoAcolhimentoRecorrente key={index} conversa={conversa}/>
                    ))}
                    
                </div>
            </div>
            

            
        </>
    );
  }

