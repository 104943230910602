// @mui
import {Link, Card, CardHeader, Stack, Grid, Paper, Chip, Typography} from '@mui/material';
// _mock
import {styled} from "@mui/material/styles";
import { getCoresTemplate } from 'src/utils/coresTemplate';
import { _socials } from '../../../../_mock/arrays';
// @types
import { IUserSocialLink } from '../../../../@types/user';
// components
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

type Props = {
  socialLinks: IUserSocialLink;
    assuntos: any;
    Atendente: any;
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ProfileSocialInfo({ socialLinks,assuntos,Atendente }: Props) {
  const { facebookLink, instagramLink, linkedinLink, twitterLink } = socialLinks;

  return (
    <Card className='RedesSociaisPerfilDoAtendenteCard'>

        <CardHeader title= { `Esse ${getCoresTemplate(Atendente?.TipoAtendente).nomeTipoAtendente} atende casos de:`} />

        <Grid container sx={{my: 2}}>
            {assuntos.map((post:any) => (
                <Grid item md={4} xs={6}>
                  <Item sx={{m: 0}}>
                    <Chip label={post.NomeAssunto} variant="outlined" color="primary" />
                  </Item>
                </Grid>
            ))}
        </Grid>

    </Card>
  );
}
