import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import {Atendetentes} from "../sections/@dashboard/Atendentes";



export default function AtendetesPage() {
  return (
    <>
      <Helmet>
        <title>Lista de  Atendentes - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Atendetentes  />
    </>
  );
}
