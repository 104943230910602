import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import {useNavigate} from "react-router-dom";
import {useEffect , useState} from "react";
import {
  Badge,
  Stack,
  Typography,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Button, 
  FormControl, 
  MenuItem, 
  FormHelperText,
  Menu,
  Pagination,Rating ,
  PaginationItem
} from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import Iconify from 'src/components/iconify/Iconify';
// @types

import { CustomAvatar } from 'src/components/custom-avatar';
import BadgeStatus from 'src/components/badge-status/BadgeStatus';

import './AvaliacaoIndividual.css';
import ptBR from "date-fns/locale/pt-BR/index";
import DeleteModal from "../../../components/modal/DeleteModal";
import {PATH_DASHBOARD} from "../../../routes/paths";
import axios from "../../../utils/axios";
import {useSnackbar} from "../../../components/snackbar";
import ModalAvaliacao from "../../../components/modalAvalicao/ModalAvaliacao";

type Props = {
    avalia: any;
    atualizar: any;
};

export default function AvaliacaoIndividual({avalia,atualizar}:Props) {


    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [modaldelete, setModaldelete] = useState(false);
    const [AvaliarOpen, setAvaliarOpen] = useState(false);
    const [dadosavaliar, setDadosavaliar] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const verPerfil = () =>{
        navigate(PATH_DASHBOARD.verperfil(avalia?.IDAtendente));
    }
    const setAvaliarOpenok = (tipo:boolean) =>{
        setAvaliarOpen(tipo);
        atualizar();
    }

    const excluir = () => {
        setModaldelete(true)
    };
    const deletar = async () => {
        const id = avalia.id;
        try {

            const response = await axios.post(`/areadoheroi/avaliacao/deletar`, {
                id
            });

            enqueueSnackbar(`${response.data.message}`, {variant: 'success'});
            atualizar();
            handleClose();
            setModaldelete(false);

        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            setModaldelete(false);
            handleClose();
        }
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    const irChat = () => {

            navigate(PATH_DASHBOARD.chat.view(avalia.tokenConversa));

    };

    const [avaliacao, setAvaliacao] = useState<number | null>(4);

  return (

      <>
      <DeleteModal opendeletemodal={modaldelete} setOpen={setModaldelete} deletar={deletar} />
          <ModalAvaliacao AvaliarOpen={AvaliarOpen} setAvaliarOpen={setAvaliarOpenok} dadosAvaliar={dadosavaliar} tipo='editar'/>

    <ListItemButton
      disableGutters
      className='ItemChatAvaliacao'
    >
    
        <>
        <div className='FotoNomeDataeFuncaoChatAvaliacaoItem'>
      <ListItemAvatar >
        
          <CustomAvatar
            key={avalia.atedendetenome}
            alt={avalia.atedendetenome}
            src={avalia.aavatar}
            name={avalia.nomeAtendente}
            BadgeProps={{
              badgeContent: <BadgeStatus status='online' />,
            }}
            sx={{ width: 48, height: 48 }}
          />

      </ListItemAvatar>


            <div className='NomeDataeFuncaoChatAvaliacaoItem'>
                <Typography className='NomeChatAvaliacaoItem' >{avalia.atedendetenome}</Typography>
                <Typography className='DataChatAvaliacaoItem' >{formatDistanceToNowStrict(new Date(avalia.created_at), {
                    addSuffix: true,
                    locale: ptBR,
                })}</Typography>
                <Rating name="read-only" value={avalia.estrelas} readOnly />

                <Typography className='AvaliacaoEscrita'>{avalia.texto}</Typography>
            </div>
        </div>

          <Stack alignItems="flex-end" className='ButtonsItemChatAvaliacao' sx={{ ml: 2, height: 44 }}>
            <Button variant='contained' onClick={irChat} > Chat </Button>
                

                  <Button
                        className="BotaoMais_AtendentesHistoricoAcolhimentoRecorrente"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Iconify icon="eva:more-vertical-fill" />
                  </Button>
                  <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={()=>{ setAvaliarOpen(true); setDadosavaliar(avalia)}}>Editar</MenuItem>
                        <MenuItem onClick={excluir}>Excluir</MenuItem>
                        <MenuItem onClick={verPerfil}>Ver Perfil</MenuItem>
                    </Menu>
                    

          </Stack>
        </>
 
     
    </ListItemButton>
      </>
  );


// ----------------------------------------------------------------------

};
