// @mui
import { styled } from '@mui/material/styles';
import { Link, Card, Typography, CardHeader, Stack, Button } from '@mui/material';
// @types
import { getCoresTemplate } from 'src/utils/coresTemplate';
import { IUserProfileAbout } from '../../../../@types/user';
// components
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------
interface Props {
  Atendente: any;
}
export default function ProfileAbout({
                                       Atendente,

}: Props) {



  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  return (
    <Card className='PerfilDoAtendenteCard'>
      {Atendente?.TipoAtendente === 2 ? (  <CardHeader title="Perfil do Psicólogo(a)" />):(<CardHeader title="Perfil do Atendente" />)}


      <Stack spacing={2} sx={{ p: 3 }}>

        <Stack direction="row">
          <StyledIcon icon="eva:pin-fill" />
          <Typography variant="body2">
          <b>Mora em</b> {Atendente.estado} </Typography>
        </Stack>

        {Atendente.ATexto === '1' ? ('') : (
            <Stack direction="row">
            <StyledIcon icon="ic:round-business-center" />
  
            <Typography variant="body2">
             Atendente.ATexto
            </Typography>
            
          </Stack>
        )}

        <Stack direction="row">
          <StyledIcon icon="eva:calendar-fill" />

          {Atendente?.TipoAtendente === 2 ? (     <Typography variant="body2"><b>Psicólogo(a) Eyhe desde:</b>  {formatDate(Atendente.created_at)}  </Typography>):(   <Typography variant="body2"><b> {getCoresTemplate(Atendente?.TipoAtendente).nomeTipoAtendente}  Eyhe desde:</b>  {formatDate(Atendente.created_at)}  </Typography>)}
        </Stack>

        {Atendente?.TipoAtendente === 2 ? (
            <>
              <Stack direction="row">
                <StyledIcon icon="eva:browser-fill" />
                <Typography variant="body2"> <b>CRP</b>  {Atendente.crp} </Typography>
              </Stack>

              <Stack direction="row">
                <StyledIcon icon="eva:book-open-outline" />
                <Typography variant="body2"><b>Formação</b> {Atendente.Formacao}  </Typography>
              </Stack>

              {Atendente.TempoExperiencia === null ? ('') : (
                <Stack direction="row">
                  <StyledIcon icon="eva:calendar-fill" />
                  <Typography variant="body2"><b>Tempo de experiência</b>  {Atendente.TempoExperiencia}  </Typography>
                </Stack>
              )}
            </>

        ):( '')}


        
      </Stack>
    </Card>
  );
}
