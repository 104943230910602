import { Helmet } from 'react-helmet-async';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// sections
import {Agendamento} from "../sections/@dashboard/Agendamento";
import Reagendamento from "../sections/@dashboard/Agendamento/Reagendamento";



export default function ReagendamentoPage() {

  const {idAtendente = '',idAgendamento} = useParams();

  return (
    <>
      <Helmet>
        <title>Agendamentos do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      
        <Reagendamento atendenteid={idAtendente} idAgendamento={idAgendamento} />
                    
    </>
  );
}
