import {Divider, IconButton, List, MenuItem, Paper, Select, Stack, Tooltip, Typography, Popover, Button} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Iconify from "../../../../components/iconify";

import "./saladeaudio.css";

type props = {
    UrlVoltar: any;
};
export default function HeaderSaladeAudio({UrlVoltar}:props) {


    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };


      const UrlVoltar2 = () => {
          console.log('teste header');
          UrlVoltar();
      };

      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;



    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    
    return (
        <>
<div className='headerAudio'>

        <Stack direction="row" spacing={2}   justifyContent="space-between" className="ItemsHeaderAudio">
                <Item>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={UrlVoltar2}
                        aria-label="close"
                    >
                        <Iconify icon="eva:arrow-ios-back-fill" />
                    </IconButton>
                    Chat
                </Item>
                <Item>
                    {/* eslint-disable-next-line react/button-has-type */}
                    {open === true ? 
                    (<button type="button" aria-describedby={id} onClick={handleClick} className='botao'>
                        <Iconify icon="eva:close-fill" />
                    </button>) : 
                    (<button type="button" aria-describedby={id} onClick={handleClick} className='botao'>
                        <Iconify icon="eva:more-vertical-fill" />
                    </button>)}
  
                </Item>

            </Stack>


            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
            </Popover>

</div>



        </>
);
}
