import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { useSettingsContext } from '../components/settings';
import {Dashfirst} from "../sections/@dashboard/Dashfirst";
import {Pagamento} from "../sections/@dashboard/Pagamento";

// ----------------------------------------------------------------------

export default function PagePagamento() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Pagamentos do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Pagamento/>
    </>
  );
}
