import {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Grid, 
    Paper,
    Table,
    TableBody ,
    TableCell ,
    TableContainer,
    TableHead ,
    TableRow ,
    FormControl,InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
     Button, Stack, Pagination
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

import image_confirmacao from './assets/img_confirmacao.png';

import './ConfirmarCadastro.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


export default function ConfirmarCadastro() {
    const navigate = useNavigate();

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className='ConfirmarCadastroPage'
                >
                    <Grid item xs={4}>
                        <Item>
                            <img src={image_confirmacao} className='ImageConfirmarCadastro' alt='Imagem de Confirmação'/>
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>
                            <Typography className='TituloConfirmarCadastro'>Confirmação via email</Typography>
                            <Typography className='TextoConfirmarCadastro'>Verifique a caixa de entrada do email cadastrado e confirme seu registro na plataforma! É simples, rápido e fácil.</Typography>
                        </Item>
                    </Grid>
                    
            </Grid>
       
        </>
    )
}
