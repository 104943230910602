import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {useCallback, useEffect, useState} from "react";
import {Box, Rating,TextField} from "@mui/material";
import axios from "../../utils/axios";
import Iconify from "../iconify";
import {useSnackbar} from "../snackbar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
x
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


const labels: { [index: string]: string } = {
    0.5: '',
    1: '',
    1.5: '',
    2: '',
    2.5: 'Ok',
    3: 'Bom',
    3.5: 'Muito bom',
    4: 'Excelente',
    4.5: 'Espetacular',
    5: 'Atendimento Perfeito',
};
function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}


type props = {
    AvaliarOpen: any,
    setAvaliarOpen: any,
    dadosAvaliar: any,
    tipo: any,
};
export default function ModalAvaliacao({AvaliarOpen,setAvaliarOpen,dadosAvaliar,tipo}:props) {

    const [value, setValue] = React.useState<any >(5);
    const [hover, setHover] = React.useState(-1);
    const [texto, settexto] = React.useState(dadosAvaliar.texto);
    const { enqueueSnackbar } = useSnackbar();



    const avaliarbotao = async (event: any) => {

        if(event){
            setValue(event);
        }else{
            setValue(value);
        }


    }




    const [avaliacao, setavaliacao] = useState<any>({});

    const getmessage = useCallback(async () => {
        try {
            const response = await axios.post(`/areadoheroi/avaliacao`,dadosAvaliar);

            const {avalia} = response.data;

            setavaliacao(avalia);
            setValue(avalia.estrelas);
            settexto(avalia.texto);

        } catch (error) {
            console.error(error);
        }
    },[dadosAvaliar]);



    useEffect(() => {
            if(tipo ==='editar'){
                getmessage();
            }


    }, [getmessage, tipo]);

    const avaliar = async () => {
        let id = '';
        if (tipo==='editar'){
            id = dadosAvaliar.IDAtendimento;
        }else{
            id = dadosAvaliar.id;
        }


        try {

            const response = await axios.post(`/areadoheroi/avaliar`, {
                id,
                IDAtendente:dadosAvaliar.IDAtendente,
                estrelas:value,
                texto
            });

            enqueueSnackbar(`${response.data.message}`, {variant: 'success'});
            handleClose();

        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            handleClose();
        }
    };
    const handleClickOpen = () => {
        setAvaliarOpen(false);
        avaliar();
    };
    const handleClose = () => {
        setAvaliarOpen(false);
    };
    const handletexto = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {

            settexto(event.target.value);

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={AvaliarOpen}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Faça a avaliação do seu atendimento
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <div className="Avaliacao">
                        <Typography variant='subtitle2' sx={{mb: 1}}>Clique nas estrelas abaixo para avalir o atendimento realizado.</Typography>
                            <Box
                                sx={{
                                    width: 500,
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxWidth: '100%',
                                }}
                            >
                                <Rating
                                    name="hover-feedback"
                                    value={value}
                                    precision={1}
                                    defaultValue={1}
                                    getLabelText={getLabelText}
                                    onChange={(event, newValue) => {
                                        avaliarbotao(newValue);
                                    }}
                                    // onChangeActive={(event, newHover) => {
                                    //     setHover(newHover);
                                    // }}
                                    emptyIcon={<Iconify icon="eva:star-fill"/>}
                                />
                                {value !== null && (
                                    <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                )}
                            </Box>

                            <Box
                                sx={{
                                    width: 500,
                                    maxWidth: '100%',
                                    marginTop:'20px'
                                }}
                            >
                                <Typography variant='subtitle2' sx={{mb: 1}}>Como foi o seu atendimento?</Typography>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    fullWidth
                                    onChange={handletexto}
                                    rows={4}
                                    defaultValue={texto}
                                    label="Resuma como foi a sua experiência e o que você sentiu após o atendimento"
                                />
                            </Box>
                        </div>
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant='contained' onClick={handleClickOpen}>
                        Salvar Avaliação
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
