import React from "react";
import './saladeaudio.css';

export default function Cronometro(props:any) {
    return (
        <div className="timer">
      <span className="digits">
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {(`0${  Math.floor((props.time / 60000) % 60)}`).slice(-2)}:
      </span>
            <span className="digits">
                {/* eslint-disable-next-line react/destructuring-assignment */}
                {(`0${  Math.floor((props.time / 1000) % 60)}`).slice(-2)}
      </span>

        </div>
    );
}