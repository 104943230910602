import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import {useNavigate} from "react-router-dom";
import {useEffect , useState} from "react";
import {
  Badge,
  Stack,
  Typography,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Button, 
  FormControl, 
  MenuItem, 
  FormHelperText,
  Menu,
  Chip,
  Pagination,
  PaginationItem
} from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import Iconify from 'src/components/iconify/Iconify';
// @types
import { IChatConversation } from '../../../../@types/chat';
// components
import { CustomAvatar, CustomAvatarGroup } from '../../../../components/custom-avatar';
import BadgeStatus from '../../../../components/badge-status';

import {PATH_DASHBOARD} from "../../../../routes/paths";

import './ChatAtendimentoItem.css';
import axios from "../../../../utils/axios";
import {useSnackbar} from "../../../../components/snackbar";
import DeleteModal from "../../../../components/modal/DeleteModal";
import ModalAvaliacao from "../../../../components/modalAvalicao/ModalAvaliacao";


// ----------------------------------------------------------------------
type Props = {
    openNav: any;
    lido: boolean;
    conversa: any;

    tokenConversa: any;
    atualizar: any;
    onSelect: VoidFunction;
};
export default function ChatAtendimentoItem({ lido = true, openNav  , onSelect,atualizar, conversa,tokenConversa}: Props) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { enqueueSnackbar } = useSnackbar();
    const open = Boolean(anchorEl);
    const [modaldelete, setModaldelete] = useState(false);
    const [AvaliarOpen, setAvaliarOpen] = useState(false);
    const [dadosavaliar, setDadosavaliar] = useState(false);

    const verPerfil = () =>{
        console.log('sasasass');
        navigate(PATH_DASHBOARD.verperfil(conversa?.IDAtendente));
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const excluir = async () => {

        const id = conversa.id;
        try {

            const response = await axios.post(`/areadoheroi/atendimentos/deletar`, {
                id
            });

            enqueueSnackbar(`${response.data.message}`, {variant: 'success'});
            atualizar();
            handleClose();
            setModaldelete(false);

        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            setModaldelete(false);
            handleClose();
        }
    };


    const deletar = () => {
        setModaldelete(true)
    };

    const verfatura = () => {
        const win = window.open(conversa.UrlPayment, '_blank');
        win?.focus();
    };

    const navigate = useNavigate();
    useEffect(() => {
        if(tokenConversa === ''){
            navigate(PATH_DASHBOARD.chat.view(conversa.tokenConversa));
        }

    }, [navigate,tokenConversa,conversa]);


    

    console.log(openNav);
    const isSelected = conversa.tokenConversa === tokenConversa ;
  return (
      <>
          <DeleteModal opendeletemodal={modaldelete} setOpen={setModaldelete} deletar={excluir} />

          <ModalAvaliacao AvaliarOpen={AvaliarOpen} setAvaliarOpen={setAvaliarOpen} dadosAvaliar={dadosavaliar} tipo='criar'/>

    <ListItemButton
      disableGutters
      
      sx={{
        py: 1.5,
        px: 2.5,
          ...(isSelected && {
              bgcolor: 'action.selected',
          })
      }}
      className='ItemChatAtendimento'
    >
    { openNav === true ?(
        <>
        <div className='FotoNomeDataeFuncaoChatAtendimentoItem'>
      <ListItemAvatar onClick={onSelect}>
          <CustomAvatar
            key={conversa.nomeAtendente}
            alt={conversa.nomeAtendente}
            src={conversa.avatarAtendente}
            name={conversa.nomeAtendente}

            BadgeProps={{
              badgeContent: <BadgeStatus status='online' />,
            }}
            sx={{ width: 48, height: 48 }}
          />

      </ListItemAvatar>

        
      
          


            <div className='NomeDataeFuncaoChatAtendimentoItem'>
                <Typography className='NomeChatAtendimentoItem' onClick={onSelect}>{conversa.nomeAtendente}</Typography>
                <Typography className='DataChatAtendimentoItem' onClick={onSelect}>{conversa.dateUltimaMessagem2}</Typography>
                <Typography className='FuncaoChatAtendimentoItem' onClick={onSelect}>{conversa.tipoAtendente}</Typography>
                <Chip label={conversa.Status} variant="outlined" color="success" />
            </div>
        </div>

          <Stack alignItems="flex-end" className='ButtonsItemChatAtendimento' sx={{ ml: 2, height: 44 }}>
            <Button variant='contained' onClick={onSelect}> Chat </Button>

                    <Button
                        className="BotaoMais_AtendentesHistoricoAcolhimentoRecorrente"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Iconify icon="eva:more-vertical-fill" />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={()=>{ setAvaliarOpen(true); setDadosavaliar(conversa)}}>Avaliar</MenuItem>
                        
                        <MenuItem onClick={verPerfil}>Ver Perfil</MenuItem>
                        <MenuItem onClick={deletar}>Deletar</MenuItem>
                        {conversa.UrlPayment && conversa.isPag ?(<MenuItem onClick={verfatura}>Ver Fatura</MenuItem>): ('') }

                    </Menu>

          </Stack>
        </>
        ):''
        }
    </ListItemButton>

    </>
  );


// ----------------------------------------------------------------------

};
