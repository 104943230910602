import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageDashfirst = Loadable(lazy(() => import('../pages/PageDashfirst')));
export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
export const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const ChatPage = Loadable(lazy(() => import('../pages/ChatPage')));
export const ChatVideo = Loadable(lazy(() => import('../pages/ChatVideo')));
export const ChatAudio = Loadable(lazy(() => import('../pages/ChatAudio')));
export const ConversasPage = Loadable(lazy(() => import('../pages/AtendimentoPage')));
export const Agendamentos = Loadable(lazy(() => import('../pages/AgendamentosPage')));
export const Atendentes = Loadable(lazy(() => import('../pages/AtendetesPage')));
export const salaespera = Loadable(lazy(() => import('../pages/PageSalaEspera')));
export const pagamento = Loadable(lazy(() => import('../pages/PagePagamento')));
export const perfil = Loadable(lazy(() => import('../pages/PersonalizarPerfilPage')));

export const verperfil = Loadable(lazy(() => import('../pages/VerPerfilPage')));
export const editarperfil = Loadable(lazy(() => import('../pages/EditarPerfilPage')));
export const Agendamento = Loadable(lazy(() => import('../pages/AgendamentoPage')));
export const Conversar = Loadable(lazy(() => import('../pages/ConversarPage')));

export const confimarcadastro = Loadable(lazy(() => import('../pages/ConfimarCadastroPage')));
