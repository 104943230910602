// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers';
import {Stack, TextField, MenuItem, TextFieldProps} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'dayjs/locale/pt-br';
// components


type Props = TextFieldProps & {
    name: string;
};

export default function RHFnascimento({ name, helperText, ...other }: Props) {
    const { control, watch } = useFormContext();

    const values = watch();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br' >
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <DatePicker
                        label={field.value ? '' : other.label}
                        value={field.value}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
                        )}
                    />
                )}
            />
        </LocalizationProvider>
    );
}
