import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import Agendamentos from "../sections/@dashboard/Agendamentos/Agendamentos";



export default function AgendamentosPage() {
  return (
    <>
      <Helmet>
        <title>Lista de  Agendamentos do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Agendamentos/>
    </>
  );
}
