/* eslint-disable no-underscore-dangle */
import EventEmitter from 'events';
import AudioFallbackVideoStates from './audioFallbackVideoStates';

const createAudioFallbackVideoStateMachine = () => {
  const ee = new EventEmitter();
  let state = AudioFallbackVideoStates.ACTIVE_VIDEO;

  return Object.assign(ee,
    {
      setState(newState) {
        if (!Object.values(AudioFallbackVideoStates).includes(newState)) {
          throw new Error('Invalid video state: ', newState);
        }
        if (newState === state) {
          return;
        }
        const previousState = state;
        state = newState;
        ee.emit('stateChange', { state, previousState });
      },
      reset() {
        state = AudioFallbackVideoStates.ACTIVE_VIDEO;
      },
    });
};

export default createAudioFallbackVideoStateMachine;
