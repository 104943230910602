import {Divider, IconButton,Button, List, Paper, Box,Menu ,MenuItem, Grid,FormControl,OutlinedInput,InputAdornment, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import {useSettingsContext} from "../settings";
import {useAuthContext} from "../../auth/useAuthContext";
import Iconify from "../iconify";
import MenuPopover from "../menu-popover";
import { PATH_DASHBOARD } from '../../routes/paths';

import BadgeStatus, {BadgeStatusValue} from "../badge-status";

import './AtendentesHistoricoAcolhimentoRecorrente.css';

import fotoAtendente from './fotoAtendente.jpg';
import ModalAvaliacao from "../modalAvalicao/ModalAvaliacao";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

type props = {
    conversa: any;
}

  export default function AtendentesHistoricoAcolhimentoRecorrente({conversa}:props){

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const conversar = () => {
        navigate(PATH_DASHBOARD.chat.view(conversa.tokenConversa));
    };

    const [AvaliarOpen, setAvaliarOpen] = useState(false);
    const [dadosavaliar, setDadosavaliar] = useState(false);

    return (
        <>

        
        <Grid 
            container
            spacing={2}>

            <Grid item xs={12}  sx={{display: "flex", alignItems: 'start', justifyContent: 'space-between', mb: 1}}>
                <div>
                    <Item className="DadosAtendenteAtendimentoRecorrente">
                        <CustomAvatar
                            className="FotoPerfilAtendente"
                            src={conversa?.avatar}
                            alt={conversa?.nome}
                            name={conversa?.nome}
                            sx={{ cursor: 'pointer', width: 40, height: 40, mr: 2 }}
                        />
                    
                        <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        >
                            <Typography className="NomeAtendenteAtendentesHistoricoAcolhimentoRecorrente" sx={{textAlign: 'Left'}} >{conversa?.nome}</Typography>
                            <Typography className="DataAtendentesHistoricoAcolhimentoRecorrente">{conversa?.data}</Typography>
                            <Rating name="half-rating" className="AvaliacaoHistoricoAcolhimentoRecorrente" defaultValue={conversa?.estrelas} readOnly/>
                        </Grid>
                    </Item>
                </div>
                <div className="DadosAtendenteAtendimentoRecorrente">
                    <Item className="StatusEBotaoMais_AtendentesHistoricoAcolhimentoRecorrente">
                    
                    <div className="StatusAtendentesHistoricoAcolhimentoRecorrente">{conversa?.status}</div>

                        <Button
                            className="BotaoMais_AtendentesHistoricoAcolhimentoRecorrente"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <Iconify icon="eva:more-vertical-fill" />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={conversar}>Acessar Chat</MenuItem>
                            <MenuItem onClick={()=>{ setAvaliarOpen(true); setDadosavaliar(conversa)}}>Avaliar</MenuItem>
                        </Menu>


                        <ModalAvaliacao AvaliarOpen={AvaliarOpen} setAvaliarOpen={setAvaliarOpen} dadosAvaliar={dadosavaliar} tipo='criar' />

                    
                    </Item>
                </div>
            
            </Grid>
        </Grid>
            

            
        </>
    );
  }

