// @mui
import { Card, Stack, Typography, Divider } from '@mui/material';
import useResponsive from "src/hooks/useResponsive";
// utils
import { fNumber } from '../../../../utils/formatNumber';
// @types
import { IUserProfileFollowers } from '../../../../@types/user';

// ----------------------------------------------------------------------
interface Props {
  valor: any;
  tempo: any;
  atedimentos: any;
  valorChat?:  any;
  TipoAtendente?: any;
}
export default function ProfileFollowInfo({ valor, valorChat, tempo,atedimentos, TipoAtendente }: Props) {

  const isDesktop = useResponsive('up', 'lg');

  return (
    <Card sx={isDesktop ? { py: 3 } : { py: 3, my: 2 }}  className='CardFollowers'>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
        <Stack width={1} textAlign="center">
        <Typography variant="h4"> R$ {TipoAtendente !== 1 ? ( valorChat) : (valor)}  </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {tempo} Minutos
          </Typography>
        </Stack>

        <Stack width={1} textAlign="center">
          <Typography variant="h4">{atedimentos}</Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Atendimentos Feitos
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
