import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import {SalaEspera} from "../sections/@dashboard/SalaEspera";

type Props = {
    socket: any;

};

export default function PageSalaEspera({socket}:Props) {
  return (
    <>
      <Helmet>
        <title>Sala de Espera do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <SalaEspera socket={socket} />
    </>
  );
}
