import { useState, useRef } from 'react';
// @mui

import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Paper,
  FormControl,
  OutlinedInput,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  InputLabel,
  MenuItem,
  Select,
  Rating,
  Button,
  Typography,
  TextField,
  CardContent,
  SelectChangeEvent,
  Divider,
  IconButton,
} from '@mui/material';
import { CustomAvatar } from 'src/components/custom-avatar';
import Iconify from 'src/components/iconify/Iconify';
import { LoadingButton } from '@mui/lab';
import {useNavigate} from "react-router-dom";
import ProfilePicture from '../img/profile_picture.png';
import axios from '../../../../utils/axios';
import { useSnackbar } from '../../../../components/snackbar';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';

import imgAtendimentoAndamento from './assets/notificacao_atendimento_ativo.png';

import './CardAtendimentoAndamento.css';
import {PATH_DASHBOARD} from "../../../../routes/paths";

// utils

// components

// ----------------------------------------------------------------------
type Props = {
  atendimentoemandamento: any;
};
export default function CardAtendimentoAndamento({atendimentoemandamento}:Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const irparachat = () => {
    navigate(PATH_DASHBOARD.chat.view(atendimentoemandamento.tokenConversa));
  };
  return (
    <>
      <div className="CardAtendimentoAndamentoDash">
        <img src={imgAtendimentoAndamento} alt="Atendimento em Andamento" />

        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          Chamada em andamento com {atendimentoemandamento.nome}
        </Typography>

        <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
          Acesse o chat clicando no botão abaixo
        </Typography>

        <Button onClick={irparachat} variant="contained" sx={{ mt: 4 }} color="primary">
          Acessar chat
          <Iconify icon="message-square-outlined" />
        </Button>
      </div>
    </>
  );
}
