import * as React from 'react';
import {
  Divider,
  Tabs,
  Tab,
  IconButton,
  Button,
  AlertTitle,
  TextField,
  Popover,
  DialogProps,
  Slide,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
  List,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Paper,
  Box,
  Grid,
  Badge,
  Avatar,
  Select,
  Stack,
  Rating,
  Tooltip,
  Typography,
} from '@mui/material';
import { CustomAvatar } from 'src/components/custom-avatar';
import { TransitionProps } from '@mui/material/transitions';
import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FiltrarProfissional from 'src/components/filtrar-profissional/FiltrarProfissional';
import FiltrarAssuntos from 'src/components/filtrar-assuntos/FiltrarAssuntos';
import AtendenteRecomendado from 'src/components/atendentes-recomendados/AtendentesRecomendados';
import HistoricoAcolhimentoPrimeiroAcesso from 'src/components/historico-acolhimento/HistoricoAcolhimentoPrimeiroAcesso';
import HistoricoAcolhimentoRecorrente from 'src/components/historico-acolhimento-recorrente/HistoricoAcolhimentoRecorrente';

// @mui
import useResponsive from 'src/hooks/useResponsive';
import { styled } from '@mui/material/styles';
import _mock from './componentesDashboardFirst/mock';

import Iconify from '../../../components/iconify';
import axios from '../../../utils/axios';
import { useSettingsContext } from '../../../components/settings';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import './Dashfirst.css';
import AvatarImage from '../Atendentes/ComponetesAtedentes/profile_picture.jpg';
import Atendente from '../Atendentes/ComponetesAtedentes/atendente';

import CarouselAnimation from './componentesDashboardFirst/CarouselDashboardFirst';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
import { useAuthContext } from '../../../auth/useAuthContext';
import Contribuicao from './componentesDashboardFirst/Contribuicao';
import AgendamentoMarcado from './componentesDashboardFirst/AgendamentoMarcado';
import AgendamentoRemarcado from './componentesDashboardFirst/AgendamentoRemarcado';
import { criarevento } from '../../../utils/analytics ';
import imgAtendimentoProfissional from './img/img-atendimento-profissional.png';
import DialogOrientacoes from './componentesDashboardFirst/DialogOrientacoes';
import FiltroAvancadoDesktop from './componentesDashboardFirst/FiltroAvancado';
import FiltroTipoAtendenteMobile from './componentesDashboardFirst/FiltroTipoAtendenteMobile';
import FiltroAssuntoMobile from './componentesDashboardFirst/FiltroAssuntoMobile';
import CardAtendimentoAndamento from './componentesDashboardFirst/CardAtendimentoAndamento';
import CardDivulgaPacotes from './componentesDashboardFirst/CardDivulgaPacotes';
// routes

// Defina a função Transition como uma função nomeada
function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
}

// Em seguida, use React.forwardRef para criar um componente de transição reutilizável
const ForwardedTransition = React.forwardRef(Transition);

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Dashfirst() {
  const [openAcolhimentoProfissional, setOpenAcolhimentoProfissional] = useState(false);
  const [modalOpenDialogOrientacoes, setModalOpenDialogOrientacoes] = useState(false);

  const [dadosDash, setDadosDash] = useState<any>({ totalonline: 0 });

  // Verifica se o valor 'modalVisto' existe no localStorage
  useEffect(() => {
    const modalVisto = localStorage.getItem('modalVisto');

    console.log(modalVisto);

    if (modalVisto === 'false') {
      setOpenAcolhimentoProfissional(true); // Abre o Dialog se 'modalVisto' não existir no localStorage
    }
  }, []);

  // Verifica se o valor 'modalOrientacaoVisto' existe no localStorage
  useEffect(() => {
    const modalOrientacaoVisto = localStorage.getItem('modalOrientacaoVisto');

    if (!modalOrientacaoVisto) {
      setModalOpenDialogOrientacoes(true); // Abre o Dialog se 'modalOrientacaoVisto' não existir no localStorage
    }
  }, []);

  const handleClickOpenAcolhimentoProfissional = () => {
    setOpenAcolhimentoProfissional(true);
  };

  const handleCloseAcolhimentoProfissional = () => {
    setOpenAcolhimentoProfissional(false);
    localStorage.setItem('modalVisto', 'true');
  };

  const handleClickOpenDialogOrientacoes = () => {
    setModalOpenDialogOrientacoes(true);
  };

  const handleCloseDialogOrientacoes = () => {
    setModalOpenDialogOrientacoes(false);
    localStorage.setItem('modalOrientacaoVisto', 'true');
  };

  const [dialogOpenFiltroAvancado, setDialogOpenFiltroAvancado] = useState(false);

  const handleOpenDialogFiltroAvancado = () => {
    setDialogOpenFiltroAvancado(true);
  };

  const handleCloseDialogFiltroAvancado = () => {
    setDialogOpenFiltroAvancado(false);
  };

  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

  const [recorrencia, setRecorrencia] = useState(false);
  const [Recorrenciaagendamento, setRecorrenciaagendamento] = useState(false);

  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const navigate = useNavigate();
  const [valueAssunto, setValueAssunto] = useState('');
  const [valueProfissional, setValueProfissional] = useState('');

  const [Atendentes, setAtendentes] = useState<any[]>([]);
  const [query, setquery] = useState<any>('');
  const [tipoprofissional, settipoprofissional] = useState<any>('');
  const [genero, setgenero] = useState<any>('');
  const [preco, setpreco] = useState<any>('');
  const [idade, setidade] = useState<any>('');
  const [avaliacao, setavaliacao] = useState<any>('');
  const [limite, setlimite] = useState<any>('');
  const { user, logout, getuser } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    getuser();
  }, [getuser]);

  useEffect(() => {
    if (user?.OrigemCampanha) {
      settipoprofissional(user?.OrigemCampanha);
    }
  }, [user]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [vazio, setisvazio] = useState<any>(false);

  const ifvazio = useCallback(async () => {
    try {
      const response = await axios.post(`/areadoheroi/atendentes`, {
        valueAssunto: '',
        query: '',
        tipoprofissional: '',
        idade: '',
        genero: '',
        avaliacao: '',
        preco: '',
      });

      const { atendentes, dados } = response.data;

      setAtendentes(atendentes.data);
      setDadosDash(dados);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getAtendentes = useCallback(async () => {
    try {
      const response = await axios.post('/areadoheroi/atendentes', {
        valueAssunto,
        query,
        tipoprofissional,
        idade,
        genero,
        avaliacao,
        limite,
        preco,
      });

      const { atendentes, dados } = response.data;
      const { data } = atendentes;

      setAtendentes(data);
      setDadosDash(dados);

      if (atendentes.data.length === 0) {
        ifvazio();
        setisvazio(true);
      } else {
        setisvazio(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [valueAssunto, query, tipoprofissional, idade, genero, avaliacao, limite, preco, ifvazio]);

  useEffect(() => {
    criarevento('primeiro_acesso');
    getAtendentes();
  }, [getAtendentes]);

  useEffect(() => {
    if (user?.social_provider === 'google') {
      if (user?.Status === 'Pendente') {
        navigate(PATH_AUTH.cadastroform);
      }
      if (user?.Status === 'Inativo') {
        logout();
      }
    }
  }, [logout, navigate, user]);

  const [ultimaconversa, setultimaconversa] = useState<any>({});
  const [ultimaconversaagendamento, setultimaconversaagendamento] = useState<any>({});

  const [filtroconversas, setfiltroconversas] = useState<any>('');

  const getultimaconversa = useCallback(async () => {
    try {
      const response = await axios.post('/areadoheroi/ultimac', {
        query: filtroconversas,
      });

      const { conversa } = response.data;

      setultimaconversa(conversa);

      if (conversa.conversasloop.length > 0) {
        setRecorrencia(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [filtroconversas]);

  useEffect(() => {
    getultimaconversa();
  }, [getultimaconversa]);

  const getagendamentos = useCallback(async () => {
    console.log('saasasas');
    try {
      const response = await axios.post('/areadoheroi/getagendamentos', {});

      const { conversa } = response.data;

      setultimaconversaagendamento(conversa);

      if (conversa.length > 0) {
        setRecorrenciaagendamento(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getagendamentos();
  }, [getagendamentos]);



  const [atendimentosemandamento, setatendimentosemandamento] = useState<any>([]);
  const getatendimentosemandamento = useCallback(async () => {
    try {
      const response = await axios.post('/areadoheroi/getatendimentosemandamento', {});

      const { conversa } = response.data;

      setatendimentosemandamento(conversa);

    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getatendimentosemandamento();
  }, [getatendimentosemandamento]);

  function currencyFormat(num: any) {
    if (num == null) {
      return `R$0,00`;
    }
    num = parseFloat(num.replace(',', '.'));

    return `R$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }

  const pageantedentes = () => {
    navigate(PATH_DASHBOARD.atendentes);
  };
  const pagamentos = () => {
    navigate(PATH_DASHBOARD.pagamento('1'));
  };
  const editarPerfil = () => {
    navigate(PATH_DASHBOARD.editarperfil);
  };

  const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setquery(event.target.value);
    } catch (error) {
      console.error(error);
    }
  };

  let message;
  if (dadosDash.tipoprofissional === 'todos' && dadosDash.assunto === 'todos') {
    message = `Mostrando <b>${dadosDash.totalonline}</b> atendentes online nesse momento. Entre eles: Anjos, Psicólogos, Terapeutas e Coaches.`;
  } else if (dadosDash.tipoprofissional !== 'todos' && dadosDash.assunto === 'todos') {
    message = `Mostrando <b>${dadosDash.totalonline}</b> ${dadosDash.tipoprofissional}${
      dadosDash.totalonline !== 1 && dadosDash.tipoprofissional !== 'Anjo Eyhe' ? 's' : ''
    } online nesse momento.`;
  } else {
    message = `Mostrando <b>${dadosDash.totalonline}</b> ${dadosDash.tipoprofissional}${
      dadosDash.totalonline !== 1 ? 's' : ''
    } online que conversam sobre ${dadosDash.assunto} nesse momento.`;
  }

  const avatar = (
    <CustomAvatar
      className="FotoPerfilAtendente"
      src={user?.avatar}
      alt={user?.nome}
      name={user?.nome}
      onClick={editarPerfil}
      sx={
        isDesktop
          ? {
              cursor: 'pointer',
              width: 100,
              height: 100,
              border: '2px solid #fff',
              marginBottom: '-25px',
            }
          : { cursor: 'pointer', width: 60, height: 60, border: '2px solid #fff' }
      }
    />
  );

  const nome = (
    <Typography className="NomeCardTopoPrincipalDashFirstDesktop">{user?.nome}</Typography>
  );

  const meusAgendamentos = (
    <div className="AgendamentosDashHeroiCard">
      {Recorrenciaagendamento ? (
        <div className="HeaderAgendamento">
          <Typography>Meus Agendamentos </Typography>
        </div>
      ) : (
        ''
      )}

      {Recorrenciaagendamento
        ? ultimaconversaagendamento.map((agendamento: any, index: any) =>
            agendamento.isreagendado === 1 ? (
              <AgendamentoRemarcado
                recarregar={getagendamentos}
                key={index}
                agendamento={agendamento}
              />
            ) : (
              <AgendamentoMarcado key={index} agendamento={agendamento} />
            )
          )
        : ''}
    </div>
  );

  const acolhimentoeSaldo = (
    <>
      {recorrencia ? (
        <div className="BotoesCardTopoPrincipalDashFirst">
          <div className="btnBuscarAnjoCardTopoPrincipalDashFirst">
            <Typography className="QtdAcolhimentos">{user?.totalacolhimento}</Typography>
            <Typography className="TextoComplementarCardTopoPrincipalDashFirst">
              Acolhimentos
            </Typography>
          </div>

          <div className="btnAdicionarSaldoCardTopoPrincipalDashFirst">
            <Typography className="SaldoAtual">{currencyFormat(user?.SaldoAtual)}</Typography>
            <Typography className="TextoComplementarCardTopoPrincipalDashFirst">
              Meu Saldo
            </Typography>
          </div>
        </div>
      ) : (
        <div className="BotoesCardTopoPrincipalDashFirst">
          <div className="btnBuscarAnjoCardTopoPrincipalDashFirst">
            <Button type="button" variant="contained" onClick={pageantedentes}>
              Buscar Atendente
            </Button>
          </div>

          <div className="btnAdicionarSaldoCardTopoPrincipalDashFirst">
            <Button type="button" variant="outlined" onClick={pagamentos}>
              {' '}
              Adicionar saldo
            </Button>
            <Typography className="TextoComplementarCardTopoPrincipalDashFirst">
              {currencyFormat(user?.SaldoAtual)}
            </Typography>
          </div>
        </div>
      )}
    </>
  );

  const teste = () => {
    console.log(Atendentes);
  };

  return (
    <>
      <div className="DashboardFirst">
        <Alert
          onClick={handleClickOpenDialogOrientacoes}
          className="AlertDashAtendimentoProfissional"
          sx={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#5cb85c',
            color: '#fff',
            mb: 1.5,
          }}
        >
          <b>
            Clique aqui para entender como funciona o Eyhe e resgatar
            <u> seu cupom de desconto</u>
          </b>{' '}
        </Alert>
        <Typography className="BemVindoTituloDashFirst">Bem-vindo, {user?.nome}</Typography>

        {isDesktop ? (
          <div className="CapaAtendenteDashFirstDesktop">
            <div className="CapaAtendenteDashFirstDesktopAzul">
              <div className="FotoeNomeAtendenteDashFirstDesktop">
                {avatar}
                {nome}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {isDesktop ? (
                <div>
                  <div>
                    {acolhimentoeSaldo}

                    {meusAgendamentos}

                    {atendimentosemandamento.map((atendimentoemandamento: any, index: any) => (
                     <CardAtendimentoAndamento key={index} atendimentoemandamento={atendimentoemandamento} />
                    ))}
                    {/* meusAgendamentos */}

                    {recorrencia === true ? (
                      <HistoricoAcolhimentoRecorrente
                        filtrar={setfiltroconversas}
                        conversas={ultimaconversa.conversasloop}
                      />
                    ) : (
                      <HistoricoAcolhimentoPrimeiroAcesso />
                    )}

                    <div className="CarouselDashboardInicial">
                      <CarouselAnimation />
                    </div>

                    <div className="CardAzulDashInicial">
                      <Contribuicao />
                    </div>

                    <CardDivulgaPacotes/>
                  </div>
                </div>
              ) : (
                ''
              )}
            </Grid>
            <Grid item md={8}>
              {isDesktop ? (
                <div>
                  <div className="BuscaComoEstaSeSentindoDashInicialDesktop">
                    <Typography variant="subtitle2">Selecione alguém para conversar</Typography>

                    <div className="BuscaComoEstaSeSentindoDashInicialDesktopInputeBotao">
                      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <TextField
                          id="input-with-sx"
                          label="Busque por nome"
                          onChange={handleChangeSearch}
                          variant="outlined"
                        />
                      </Box>

                      <div className="BuscaComoEstaSeSentindoDashInicialDesktopBotoes">
                        <Button
                          type="button"
                          variant="contained"
                          onClick={pageantedentes}
                          sx={{ mr: 0.5 }}
                        >
                          {' '}
                          <Iconify icon="eva:search-outline" sx={{ mr: 1 }} /> Buscar Atendente
                        </Button>

                        <Button
                          type="button"
                          variant="outlined"
                          onClick={pagamentos}
                          sx={{ ml: 0.5 }}
                        >
                          {' '}
                          <Iconify icon="eva:plus-fill" sx={{ mr: 1 }} /> Adicionar Saldo
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="FiltrarAssuntosDashInicial">
                    <FiltrarProfissional filtrarProficional={settipoprofissional} />
                    <FiltrarAssuntos filtrarAssunto={setValueAssunto} />
                  </div>

                  {vazio ? (
                    <>
                      <div
                        style={{
                          background: '#FFF5CC',
                          borderRadius: '8px',
                          padding: '8px 16px',
                          marginBottom: '5px',
                        }}
                      >
                        <Typography sx={{ textAlign: 'center', color: '#7A4100' }}>
                          Não há anjos/profissionais para este assunto ou busca. <br /> Atendentes
                          online agora!
                        </Typography>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  <Grid className="CardTopoAtendentes">
                    <Grid item xs={12}>
                      <Item>
                        <Typography
                          sx={{ textAlign: 'left' }}
                          variant="subtitle2"
                          dangerouslySetInnerHTML={{ __html: message }}
                        />
                      </Item>
                    </Grid>
                  </Grid>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {Atendentes.length === 0 ? (
                      <Item className="itensAtendentesDash">
                        <div className="itensAtendentesDashdiv">
                          {' '}
                          <p>Não há anjos/profissionais para este assunto.</p>
                        </div>
                      </Item>
                    ) : (
                      <>
                        {Atendentes.map((Atendentevar: any, index: any) => (
                          <Grid item xs={12} md={12} key={index}>
                            <Item className="itensAtendentesDash">
                              <Atendente atendente={Atendentevar} />
                            </Item>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </div>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {isDesktop ? (
                <div />
              ) : (
                <>
                  {atendimentosemandamento.map((atendimentoemandamento: any, index: any) => (
                      <CardAtendimentoAndamento key={index} atendimentoemandamento={atendimentoemandamento} />
                  ))}

                  <div className="FiltrosDashboardInicial">
                    <FiltrarProfissional filtrarProficional={settipoprofissional} />

                    <FiltroAssuntoMobile setassunto={setValueAssunto} />

                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ py: 1 }}
                      fullWidth
                      onClick={handleOpenDialogFiltroAvancado}
                    >
                      <Iconify icon="eva:funnel-outline" sx={{ mr: 1 }} />
                      Filtros Avançados
                    </Button>

                    <Grid className="CardTopoAtendentes" sx={{ mt: 2 }}>
                      <Grid item xs={12}>
                        <Item>
                          <Typography
                            sx={{ textAlign: 'left' }}
                            variant="subtitle2"
                            dangerouslySetInnerHTML={{ __html: message }}
                          />
                        </Item>
                      </Grid>
                    </Grid>
                  </div>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              {isDesktop ? (
                <>
                  <Button
                    type="button"
                    sx={{ mt: 1.5, p: 1.5 }}
                    variant="contained"
                    fullWidth
                    onClick={pageantedentes}
                  >
                    {' '}
                    <Iconify icon="eva:plus-outline" /> Ver mais atendentes
                  </Button>
                  <div />
                </>
              ) : (
                <div>
                  <div className="ListadeAtendentesDashFirst">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      {vazio ? (
                        <div
                          style={{
                            background: '#FFF5CC',
                            borderRadius: '8px',
                            padding: '8px 16px',
                          }}
                        >
                          <Typography sx={{ textAlign: 'center', color: '#7A4100' }}>
                            Não há anjos/profissionais para este assunto ou busca. <br /> Atendentes
                            online agora!
                          </Typography>
                        </div>
                      ) : (
                        ''
                      )}

                      {Atendentes.length === 0 ? (
                        <Item className="itensAtendentesDash">
                          <div className="itensAtendentesDashdiv">
                            {' '}
                            <p>Não há anjos/profissionais para este assunto.</p>
                          </div>
                        </Item>
                      ) : (
                        Atendentes.map((Atendentevar: any, index: any) => (
                          <Grid item xs={12} md={12} key={index}>
                            <Item className="itensAtendentesDash">
                              <Atendente atendente={Atendentevar} />
                            </Item>
                          </Grid>
                        ))
                      )}
                    </Grid>
                    <Button
                      type="button"
                      sx={{ my: 1.5, p: 1.5 }}
                      variant="contained"
                      fullWidth
                      onClick={pageantedentes}
                    >
                      {' '}
                      <Iconify icon="eva:plus-outline" /> Ver mais atendentes
                    </Button>
                  </div>

                  <div className="CarouselDashboardInicial">
                    <CarouselAnimation />
                  </div>

                  <div className="CardAzulDashInicial">
                    <Contribuicao />
                  </div>

                  <CardDivulgaPacotes/>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>

      <DialogOrientacoes
        openDialogOrientacoes={modalOpenDialogOrientacoes}
        handleCloseDialogOrientacoes={handleCloseDialogOrientacoes}
      />

      <Dialog
        open={openAcolhimentoProfissional}
        onClose={handleCloseAcolhimentoProfissional}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={ForwardedTransition}
        keepMounted
        scroll={scroll}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        className="modalAcolhimentoProfissional"
      >
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <DialogContent>
            <img
              src={imgAtendimentoProfissional}
              style={{ padding: '30px 0px' }}
              alt="Fundos de acolhimentos para atendimentos gratuitos"
            />

            <Typography
              variant="h3"
              sx={{ textAlign: 'center', color: '#212b36', mb: 2, lineHeight: '1.2' }}
            >
              Agora você pode ser acolhido por profissionais de Psicologia.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', color: '#212b36' }}>
              O Eyhe disponibiliza a partir de agora Psicólogas(os) para que você tenha acesso aos
              atendimentos profissionais e contínuos pela plataforma.
              <br />
              <br />
              Mais uma evolução para ajudar você a sentir-se bem e superar os desafios da vida.
              <br />
              Estamos incluindo novos profissionais diariamente. Sessões a partir de R$20.
              <br />
              <br />
              Agradecemos muito por você fazer parte da comunidade Eyhe.
              <br />
              <b>#VocêSeImporta?</b>
            </Typography>
            <DialogActions>
              <Button
                fullWidth
                onClick={handleCloseAcolhimentoProfissional}
                variant="contained"
                sx={{ py: 1.5, px: 2, my: 2.5, backgroundColor: '#7635dc', color: '#fff' }}
              >
                <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Ok! Entendido.
              </Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Dialog>

      <FiltroAvancadoDesktop
        openFiltroAvancado={dialogOpenFiltroAvancado}
        handleCloseFiltroAvancado={handleCloseDialogFiltroAvancado}
        setassunto={setValueAssunto}
        setquery={setquery}
        settipoprofissional={settipoprofissional}
        setpreco={setpreco}
        setavaliacao={setavaliacao}
        setpage={() => {
          console.log('pesquisa');
        }}
      />
    </>
  );
}
