import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import {
    IconButton,
    Typography,
    Button,
    Grid,
    Divider,
    List,
    Dialog,
    AppBar,
    Toolbar,
    Rating,
    Chip,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Box,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup,
    Paper,
    SelectChangeEvent 
} from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';


type Props = {
    mudardia: any;

};


export default function WeeklyCalendar({mudardia}:Props) {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  dayjs.locale('pt-br');

  const handlePreviousWeek = () => {
    setSelectedDate(selectedDate.subtract(1, 'week'));
  };

  const handleNextWeek = () => {
    setSelectedDate(selectedDate.add(1, 'week'));
  };

  const handleMonthYearChange = (event: SelectChangeEvent) => {
    const [year, month] = event.target.value.split('-');
    setSelectedDate(dayjs().year(Number(year)).month(Number(month) - 1));
  };

    const [dayselect, setdayselect] = useState<any>(dayjs().format('YYYY-MM-DD'));



  const handleday = (dia:any) => {
      mudardia(dia)
      setdayselect(dia)
  };


  const renderWeek = () => {
    const startOfWeek = selectedDate.startOf('week');
    const endOfWeek = selectedDate.endOf('week');
    const days = [];

    for (let day = startOfWeek; day.isBefore(endOfWeek); day = day.add(1, 'day')) {
      const isCurrentDay = day.isSame(dayjs(), 'day');

      const  isafterday = day.isAfter(dayjs(), 'day') || isCurrentDay
      days.push(
          isafterday ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <a
                      key={day.format('YYYY-MM-DD')}
                      className={`day ${isCurrentDay ? 'current-day' : ''} ${dayselect === day.format('YYYY-MM-DD') ? 'select-day' : ''}`}
                      onClick={() => {
                          handleday(day.format('YYYY-MM-DD'));
                      }}
                  >
                      {day.format('D')}
                  </a>):(
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <a
                  key={day.format('YYYY-MM-DD')}
                  className={`dayold ${isCurrentDay ? 'current-day' : ''} ${dayselect === day.format('YYYY-MM-DD') ? 'select-day' : ''}`}
              >
                  {day.format('D')}
              </a>
              )

      );
    }

    return days;
  };

  // Gere as opções para o dropdown do mês/ano
  const monthOptions = [];
for (let month = 0; month < 12; month += 1) {
  const monthDate = selectedDate.month(month);
  monthOptions.push(
    <MenuItem  key={month} className='MesesdoAno' value={`${monthDate.year()}-${monthDate.month() + 1}`}>
      {monthDate.format('MMMM')} {monthDate.year()}
    </MenuItem >
  );
}

  return (
    <div className="weekly-calendar">
      <div className="navigation">


      <FormControl>
        <InputLabel id="demo-simple-select-label">Meses</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={`${selectedDate.year()}-${selectedDate.month() + 1}`}
          label="Meses"
          onChange={handleMonthYearChange}
        >
          {monthOptions}
        </Select>
        
      </FormControl>
        <Button onClick={handlePreviousWeek}> <Iconify icon='eva:arrow-ios-back-outline'/> </Button>
        <Button onClick={handleNextWeek}> <Iconify icon='eva:arrow-ios-forward-outline'/> </Button>
        


      </div>
      
        <div className='weeksName'>
            <div className='weekName'>D</div>
            <div className='weekName'>S</div>
            <div className='weekName'>T</div>
            <div className='weekName'>Q</div>
            <div className='weekName'>Q</div>
            <div className='weekName'>S</div>
            <div className='weekName'>S</div>
        </div>
        
      <div className="week">{renderWeek()}</div>
    </div>
  );
}
