import { Helmet } from 'react-helmet-async';
// sections
import { EsqueciSenha } from 'src/sections/auth/EsqueciSenha';



export default function EsqueciSenhaPage() {
  return (
    <>
      <Helmet>
        <title>Esqueci minha Senha -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <EsqueciSenha/>
    </>
  );
}
