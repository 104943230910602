import * as React from 'react';
import {CssBaseline, Box, Container, Typography, Tooltip} from "@mui/material";
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';


import "./saladevideo.css";
// @mui
import {styled} from '@mui/material/styles';
import Dialog from "@mui/material/Dialog";
import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle


import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
// eslint-disable-next-line import/no-cycle
import HeaderSaladeVideo from "./HeaderSaladeVideo";
import RodapeSaladeVideo from "./RodapeSaladeVideo";
import EncerraSalaVideo from "./EncerraSalaVideo";

const OT = require("@opentok/client");

// ----------------------------------------------------------------------

let session: any;
let publisher: any;
let subscriber: any;

type props = {
    EncerraVideo: any;
};
export default function Espera({EncerraVideo}:props) {



    const { user } = useAuthContext();
    const {tokenConversa = ''} = useParams();
    const [usera,setuser] = useState<any>({});

    const [encerrar,setencerrar] = useState<any>(false);

    const navigate = useNavigate();






    useEffect(  () => {
        const fetchRepos2 = async () => {
            const response = await axios.get(`/areadoheroi/atendente/${tokenConversa}`);

            const {atendente} = response.data;


            setuser(atendente)
        }
        fetchRepos2();

    });


    useEffect(  () => {



        const fetchRepos = async () => {





            try {
                const response = await axios.post(`/areadoheroi/gettokentokbox`,{id:tokenConversa});


                console.log(response.data);
                const {apiKey} = response.data;
                const {sessionId} = response.data;
                const {token} = response.data;


               
               



                // eslint-disable-next-line react-hooks/exhaustive-deps
                session = OT.initSession(apiKey, sessionId);
                // create a publisher
                // eslint-disable-next-line react-hooks/exhaustive-deps
                publisher = OT.initPublisher(
                    "publisher",
                    {
                        insertMode: "append",
                        width: "100%",
                        height: "100%",
                        style: {buttonDisplayMode: 'on'},
                        showControls: true
                    }
                );
                // subscribe to newly created stream
                session.on("streamCreated", (event: any) => {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    subscriber = session.subscribe(
                        event.stream,
                        "subscriber",
                        {
                            insertMode: "append",
                            width: "100%",
                            height: "100%",
                            style: {buttonDisplayMode: 'on'},
                            showControls: true
                        }
                    );
                });

                // connect to the session
                session.connect(token, (error: any) => {
                    // If the connection is successful, publish to the session
                    if (error) { /* empty */
                    } else {
                        session.publish(publisher);
                    }
                });
                // do some action upon destroying the created stream
                session.on("streamDestroyed", (event: any) => {
                    console.log("The Video chat has ended");
                    session.disconnect();
                    navigate(PATH_DASHBOARD.chat.view(tokenConversa));
                });




            } catch (error) {
                console.error(error);
            }
        };



        fetchRepos();

    },[navigate, tokenConversa] );



    const Encerrarchamada = () =>{
        EncerraVideo('Encerra');
    }
    const Cameraon =  () =>{
        publisher.publishVideo(true);

    }

    const Cameraoff = () =>{
        publisher.publishVideo(false);
    }
    const Microfoneon = () =>{
        publisher.publishAudio(true);
    }
    const Microfoneoff = () =>{
        publisher.publishAudio(false);
    }

    const Texto = () =>{

    }

    const UrlVoltar = () =>{
        setencerrar(false)
    }
    const fecharEcerrar = () =>{
        setencerrar(false)
    }

    const fecharvideo = () =>{
        session.disconnect();
        navigate(PATH_DASHBOARD.chat.view(tokenConversa));
    }

    return (
        <>

            <Dialog
                fullScreen
                open={encerrar}
            >
                <EncerraSalaVideo Backaudio={fecharEcerrar} fecharvideo={fecharvideo} />
            </Dialog>

            <HeaderSaladeVideo UrlVoltar={UrlVoltar}/>
            <CssBaseline/>
            <Container maxWidth="xl">
                <Box sx={{bgcolor: '#fff', height: '100vh', width: '100vw'}}>

                    <Box className="GreyMaior"> </Box>
                    <Box className="GreyMenor"> </Box>

                    <div className='TextosAguardando'>
                        <Typography className='TituloAguardando'>Aguardando “Atendido”</Typography>
                        <Typography className='SubTituloAguardando'>{usera?.nome}</Typography>
                    </div>


                        <div id="videos" className="MinhaCam" >

                            <div id="publisher" />


                        </div>

                    <div className='TextosAguardando'>
                        <Typography className='SeuNome'>{user?.nome}</Typography>
                    </div>
                </Box>
            </Container>

            <RodapeSaladeVideo Encerrarchamada={Encerrarchamada} Cameraon={() => Cameraon()}  Cameraoff={() => Cameraoff()} Microfoneon={Microfoneon} Microfoneoff={Microfoneoff} Texto={Texto}/>
        </>
    );
}