import {Divider, IconButton, List, MenuItem, Paper, Select, Stack, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {useSettingsContext} from "../../../../components/settings";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";
import BadgeStatus from "../../../../components/badge-status";
import "./saladevideo.css";

type props = {
    Encerrarchamada: any,
    Microfoneon: any,
    Microfoneoff: any,
    Cameraon: any,
    Cameraoff: any,
    Texto: any,
};
export default function RodapeSaladeVideo({
                                              Encerrarchamada,
                                              Microfoneon,
                                              Microfoneoff,
                                              Cameraon,
                                              Cameraoff,
                                              Texto
                                          }: props) {



    const Item = styled(Paper)(({theme}) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    const [video, setvideo] = useState(false);
    const [audio, setvAudio] = useState(false);
    const Microfoneonf = () => {
        setvAudio(false);
        Microfoneon()
    }
    const Microfoneofff = () => {
        setvAudio(true);
        Microfoneoff()
    }
    const Cameraonf = () => {
        setvideo(false);
        Cameraon()
    }
    const Cameraofff = () => {
        setvideo(true);
        Cameraoff();
    }


    return (
        <>


            <div className='rodapeVideo'>
                <Stack direction="row" spacing={2} justifyContent="space-evenly">

                    <Item>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <Tooltip title="Encerrar Chamada">
                            <button type="button" onClick={Encerrarchamada} className='botaoFooterTurnOff'>
                                <Iconify icon="eva:phone-fill"/>
                            </button>
                        </Tooltip>
                    </Item>

                    <Item>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <Tooltip title="Ativar/Desativar Microfone">

                            {audio ? (
                                <button type="button" onClick={Microfoneonf} className='botaoFooter'>
                                    <Iconify icon="eva:mic-off-fill"/>

                                </button>
                            ):(
                                <button type="button" onClick={Microfoneofff} className='botaoFooter'>
                                    <Iconify icon="eva:mic-fill"/>
                                </button>
                            )}
                        </Tooltip>
                    </Item>

                    <Item>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <Tooltip title="Ativar/Desativar Câmera">
                            {video ? (
                                <button type="button" onClick={Cameraonf} className='botaoFooter'>
                                    <Iconify icon="eva:video-off-fill"/>
                                </button>
                            ):(
                                <button type="button" onClick={Cameraofff} className='botaoFooter'>
                                    <Iconify icon="eva:video-fill"/>
                                </button>
                            )}

                        </Tooltip>
                    </Item>

                    <Item>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <Tooltip title="Enviar Mensagem de Texto">
                            <button type="button" onClick={Encerrarchamada} className='botaoFooter'>
                                <Iconify icon="eva:message-square-fill"/>
                            </button>
                        </Tooltip>
                    </Item>

                </Stack>
            </div>


        </>
    );
}
