import {Divider, IconButton,Button, List, MenuItem, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Typography, Chip} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import {useSettingsContext} from "../../../../components/settings";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";

import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";

import AvatarImage from "./profile_picture.jpg"

import plan1 from '../img/plan_1.webp';
import plan2 from '../img/plan_2.webp';
import plan3 from '../img/plan_3.webp';
import DialogMaisInfoPacote from "./DialogMaisInfoPacote";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


type props = {
    corValor: "primary" | "secondary" | "error" | "warning" | "info" | "success" | undefined;
    corBotao: "primary" | "secondary" | "error" | "warning" | "info" | "success" | undefined;
    nomePlano: any;
    valorPlano: any;
    qtdConversasPlano: any;
    dados: any;
    alternartipoPacotes: any;
};


export default function CardsPlanosPacotes({ corValor, corBotao, nomePlano, valorPlano, qtdConversasPlano,dados,alternartipoPacotes}:props) {
 

    const [openMaisInfosPacote, setOpenMaisInfosPacote] = React.useState(false);

    const handleClickOpenMaisInfosPacote = () => {
        setOpenMaisInfosPacote(true);
    };    const selecionarpacote = () => {
        alternartipoPacotes(dados);
    };

    const handleCloseMaisInfosPacote = () => {
        setOpenMaisInfosPacote(false);
    };

    let classeDiv: string;
  let corTexto: string;

  switch (corBotao) {
    case "primary":
      classeDiv = "PlanoPopularPagamentosPacoteAzul";
      corTexto = "#2065D1"; // Azul
      break;
    case "secondary":
      classeDiv = "PlanoPopularPagamentosPacoteVerde";
      corTexto = "#36B37E"; // Verde
      break;
      case "info":
      classeDiv = "PlanoPopularPagamentosPacoteCiano";
      corTexto = "#00B8D9"; // Ciano
      break;
      case "warning":
      classeDiv = "PlanoPopularPagamentosPacoteAmarelo";
      corTexto = "#ffab00"; // Amarelo
      break;
    case "error":
      classeDiv = "PlanoPopularPagamentosPacoteVermelho";
      corTexto = "#FF5630"; // Vermelho
      break;
    case "success":
      classeDiv = "PlanoPopularPagamentosPacoteVerde";
      corTexto = "#36B37E"; // Verde
      break;
    default:
      classeDiv = ""; // Pode ser uma classe padrão ou vazia, dependendo do caso
      corTexto = ""; // Cor padrão ou vazia
  }

    const CaracteristicasPlano = (
        <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        >   
            <div className="ItensPlanosListaPagamentoPacotes">
                <Typography>Chat anônimo</Typography>
                <Iconify icon="eva:checkmark-fill"/>
            </div>
            <div className="ItensPlanosListaPagamentoPacotes">
                <Typography>Atendimento 24h</Typography>
                <Iconify icon="eva:checkmark-fill"/>
            </div>
            <div className="ItensPlanosListaPagamentoPacotes">
                <Typography>Agendamento</Typography>
                <Iconify icon="eva:checkmark-fill"/>
            </div>
            <div className="ItensPlanosListaPagamentoPacotes">
                <Typography>Reembolso garantido</Typography>
                <Iconify icon="eva:checkmark-fill"/>
            </div>
           { dados.PermiteVideook === 1 ? (
            <div className="ItensPlanosListaPagamentoPacotes">
                <Typography>Conversa por vídeo</Typography>
                <Iconify icon="eva:checkmark-fill"/>

            </div>
               ): ''}
            { dados.PermiteLigacaook === 1 ? (
            <div className="ItensPlanosListaPagamentoPacotes">
                <Typography>Conversa por voz</Typography>
                <Iconify icon="eva:checkmark-fill"/>

            </div>
            ): ''}
    
        </Grid>
    )



    return (
        <>
                                            <Grid item xs={12} md={3}>
                                            <Item className="CardPlanoPagamentoItem">
                                                <div className="CardPlanosPagamentos">
                                                    <Grid container 
                                                    spacing={2} 
                                                    justifyContent="flex-start"
                                                    alignItems="center">
                                                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-start'}}>
                                                            <div className={classeDiv} > Preço fixo </div>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography variant="h3" sx={{textAlign: 'left'}}> {nomePlano}  </Typography>
                                                            <Typography variant="subtitle2" sx={{textAlign: 'left', mt: 1.5}}>Pacote com  {qtdConversasPlano} conversas de {dados.Duracao} minutos</Typography>

                                                        </Grid>
                                                        
                                                        <Grid item xs={12}>
                                                        {CaracteristicasPlano}

                                                        </Grid>

                                                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                            <Typography className="ValorPlanoPagamentoPacote" style={{ color: corTexto }}>R$ {valorPlano}</Typography>
                                                            
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Button variant="contained" onClick={selecionarpacote} color={corBotao} fullWidth>Comprar</Button>
                                                        </Grid>
                                                    
                                                        <Grid item xs={12}>
                                                            <Button variant="text" onClick={handleClickOpenMaisInfosPacote} color={corBotao} fullWidth>Mais detalhes</Button>
                                                        </Grid>
 
                                                    </Grid>
                                                </div>
                                            </Item>
                                        </Grid>

                                       
            <DialogMaisInfoPacote dados={dados} openMaisInfosPacote={openMaisInfosPacote} handleClickOpenMaisInfosPacote={handleClickOpenMaisInfosPacote} handleCloseMaisInfosPacote={handleCloseMaisInfosPacote} />
                                        


        </>
    );
}
