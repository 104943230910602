import {Divider, IconButton,Button, List, MenuItem, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import {LoadingButton} from "@mui/lab";
import {useSettingsContext} from "../../../../components/settings";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";

import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";

import AvatarImage from "./profile_picture.jpg"

import plan1 from '../img/plan_1.webp';
import plan2 from '../img/plan_2.webp';
import plan3 from '../img/plan_3.webp';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


type props = {
    tipoconversa: any,
    tiposConversaselect:any
    enviado:any
    Agendamento:any
};




export default function TipoConversaSessaoUnica({tipoconversa,tiposConversaselect,enviado,Agendamento}:props) {




    const escolhertipo = () => {
        tiposConversaselect(tipoconversa);
    };


    const CaracteristicasPlano = (
        <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        >   
            <div className="ItensPlanosListaPagamento">
                <Iconify icon="eva:checkmark-fill"/>
                <Typography>Chat anônimo</Typography>
            </div>
            <div className="ItensPlanosListaPagamento">
                <Iconify icon="eva:checkmark-fill"/>
                <Typography>Atendimento 24h</Typography>
            </div>
            <div className="ItensPlanosListaPagamento">
                <Iconify icon="eva:checkmark-fill"/>
                <Typography>Agendamento</Typography>
            </div>
            { tipoconversa.PermiteReembolso === 1 ? (
            <div className="ItensPlanosListaPagamento">
                <Iconify icon="eva:checkmark-fill"/>
                <Typography>Reembolso garantido</Typography>
            </div>
            ): ''}
            { tipoconversa.PermiteVideo === 1 ? (
            <div className="ItensPlanosListaPagamento">
                <Iconify icon="eva:checkmark-fill"/>
                <Typography>Conversa por vídeo</Typography>
            </div>
               ): ''}
            { tipoconversa.PermiteLigacao === 1 ? (
            <div className="ItensPlanosListaPagamento">
                <Iconify icon="eva:checkmark-fill"/>
                <Typography>Conversa por voz</Typography>
            </div>
            ): ''}
    
        </Grid>
    )



    return (
        <>
            {tipoconversa.id === 1 && Agendamento === false ?(              <Grid item xs={12} md={4}>
                    <Item className="CardPlanoPagamentoItem">
                        <div className="CardPlanosPagamentos">
                            <Grid container
                                  spacing={2}
                                  justifyContent="flex-start"
                                  alignItems="center">
                                <Grid item xs={12}>
                                    <Typography className="NomePlanoPagamento">{tipoconversa.Nome} | {tipoconversa.Duracao} minutos</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography className="ValorPlanoPagamentoGratis" color="success">R${tipoconversa.Valor} </Typography>
                                </Grid>
                                <Grid item xs={8}>

                                    {CaracteristicasPlano}

                                </Grid>
                                <Grid item xs={4}>
                                    <img src={tipoconversa.Imagem} alt={tipoconversa.Nome}/>
                                </Grid>
                                <Grid item xs={12}>

                                    <LoadingButton
                                        fullWidth
                                        size="large"
                                        onClick={escolhertipo}
                                        variant="contained"
                                        color="success"
                                        loading={enviado}
                                    >
                                        Conversar grátis
                                    </LoadingButton>


                                </Grid>
                            </Grid>
                        </div>
                    </Item>
                </Grid>)
                :
                (


                                          <Grid item xs={12} md={4}>
                                                <Item className="CardPlanoPagamentoItem">
                                                        <div className="CardPlanosPagamentos">
                                                            <Grid container 
                                                            spacing={2} 
                                                            justifyContent="flex-start"
                                                            alignItems="center">
                                                                <Grid item xs={12}>
                                                                    <Typography className="NomePlanoPagamento">{tipoconversa.Nome} | {tipoconversa.Duracao} minutos</Typography>
                                                                </Grid>
                                                                
                                                                <Grid item xs={12}>
                                                                    <Typography className="ValorPlanoPagamento">R${tipoconversa.Valor} </Typography>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    
                                                                {CaracteristicasPlano}

                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <img src={tipoconversa.Imagem} alt={tipoconversa.Nome}/>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Button variant="contained" onClick={escolhertipo} fullWidth>Comprar</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Item>
                                                </Grid>
            )}
        </>
    );
}
