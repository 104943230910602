import {Divider, IconButton,Button, List, MenuItem, Paper, Box, Grid,FormControl,OutlinedInput,InputAdornment, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import {useSettingsContext} from "../settings";
import {useAuthContext} from "../../auth/useAuthContext";
import Iconify from "../iconify";
import MenuPopover from "../menu-popover";

import BadgeStatus, {BadgeStatusValue} from "../badge-status";

import './HistoricoAcolhimentoPrimeiroAcesso.css';

import fotoAtendimento from './img-historico-atendimento.jpg';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  export default function HistoricoAcolhimentoPrimeiroAcesso(){

    return (
        <>


            <div className="CardHistoricoAcolhimentoPrimeiroAcesso">
                <div className="HeaderHistoricoAcolhimentoPrimeiroAcesso">
                    <Typography>Histórico de acolhimentos</Typography>
                </div>
                <div className="InputSearchHistoricoAcolhimentoPrimeiroAcesso">
                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                        <OutlinedInput
                            id="InputBuscaporAnjoeFiltro"
                            className="InputBuscaporAnjoeFiltro"
                            startAdornment={<InputAdornment position="start"><Iconify icon="eva:search-outline"/></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                            'aria-label': 'weight',
                            }}
                        />
                    </FormControl>
                </div>
                
                <Divider/>

                <div className="MainHistoricoAcolhimentoPrimeiroAcesso">

                <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                >

                    <img src={fotoAtendimento} width="100px" alt="Nenhuma Conversa" />
                    <Typography>Você não possui nenhuma conversa até o momento</Typography>
                    
                    <Button variant="outlined">Buscar Anjo</Button>

                </Grid>

                    
                </div>
            </div>

            
        </>
    );
  }

