// @mui
import { Box, BoxProps, Typography, Grid, styled , Paper } from '@mui/material';
// hooks
import {useEffect, useState, useCallback, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import Iconify from 'src/components/iconify';

import Logo from '../../components/logo';
import metodosPagamento from './img/metodospagamento.png'

import './footer.css';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function Footer() {
 

    const location = useLocation();
  
    const [pagChat, setPagChat] = useState(false);

    useEffect(() => {
      // Verifique se o usuário está em "/chat"
      const isChatPage = location.pathname.includes('/chat');
  
      // Faça algo com base na localização
      if (isChatPage) {
        setPagChat(true)
      } else {
        setPagChat(false)
      }
    }, [location.pathname]);
  
  

  return (
    <>
    {pagChat ? ('') : (
      <div className='generalFooter'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={3} sm={12}>
          <Item sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
            <Logo/>
            <Typography variant='subtitle2' sx={{textAlign: 'left'}}>
              Psicóloga Responsável
            </Typography>
            <Typography variant='subtitle1' sx={{fontWeight: 700, textAlign: 'left'}}>
              Talita Marina Bombana - CRP 08/32891
            </Typography>
            

            <Typography sx={{mt: 2, textAlign: 'left'}}>
              contato@eyhe.com.br
            </Typography>
          </Item>
        </Grid>

        <Grid item md={5} sm={12}>
          <Item className='AtencaoFooter'>
            <Typography sx={{textAlign: 'left'}} variant='subtitle2'>Atenção: Este site não oferece tratamento ou aconselhamento imediato para pessoas em crise suicida. Em caso de crise,<b> ligue para 188 (CVV)</b> ou acesse o site <a href="https://www.cvv.org.br/" target='_blank' rel="noreferrer" style={{textDecoration: 'none', color: '#078dee'}}> www.cvv.org.br</a>. Em caso de emergência, procure atendimento em um hospital mais próximo.</Typography>
          </Item>
        </Grid>

        <Grid item md={4} sm={12}>
          <Item>
            <Typography variant='subtitle1' sx={{fontWeight: 700, textAlign: 'left'}}>
              Métodos de pagamento
            </Typography>
            <img src={metodosPagamento} width='200px' alt='Imagem contendo os metodos de pagamento'/>
          </Item>
        </Grid>

        

        
        

      </Grid>
    </div>
    )}
      </>
  );
}
