import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography,TextField, FormControlLabel, InputAdornment, IconButton } from '@mui/material';
// utils

import { RHFSelectBandeiras } from 'src/components/hook-form/RHFSelectBandeiras';
import RHFnascimento from "src/components/hook-form/RHFnascimento";
import RHFtelefone from "src/components/hook-form/RHFtelefone";
import RHFCep from 'src/components/hook-form/RHFCep';
import Iconify from 'src/components/iconify/Iconify';

import { fData } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { IUserAccountGeneral } from '../../../@types/user';
// assets
import { countries } from '../../../assets/data';
// components
import Label from '../../../components/label';
import { CustomFile } from '../../../components/upload';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../components/hook-form';
import axios from "../../../utils/axios";



// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<IUserAccountGeneral, 'avatarUrl'> {
  avatar: CustomFile | string | null;
}

type Props = {
  isEdit?: boolean;
  currentUser?: any;
};

export default function UserNewEditForm({ isEdit = false, currentUser }: Props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
      nome: Yup.string().required('Nome é obrigatorio.'),
      // email: Yup.string().required('Email é obrigatorio.').email('Precisa ser um email válido'),
      // NumeroWhatsApp: Yup.string().required('WhatsApp é obrigatorio'),
      estado: Yup.string().required('Estado é obrigatorio'),
      CodigoPais: Yup.string().required('País é obrigatorio'),
      cidade: Yup.string().required('Cidade é obrigatorio'),
      CpfOrPassaporte: Yup.string().required('CPF é obrigatorio'),
      Biografia: Yup.string().required('Biografia é obrigatorio'),

  });

  const defaultValues = useMemo(
    () => ({
        avatar: currentUser?.avatar || '',
        nome: currentUser?.nome || '',
        email: currentUser?.email || '',
        NumeroWhatsApp: currentUser?.NumeroWhatsApp || '',
        estado: currentUser?.estado || '',
        CodigoPais: currentUser?.CodigoPais || '',
        cidade: currentUser?.cidade || '',
        CpfOrPassaporte: currentUser?.CpfOrPassaporte || '',
        Biografia: currentUser?.Biografia || '',

    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const onSubmit = async (data: FormValuesProps) => {
      try {

          const response = await axios.post('/areadoheroi/editarperfil',data);
          const {user} = response.data;
          enqueueSnackbar('Editado com sucesso!');

      } catch (error) {
          enqueueSnackbar(`${ error.message}`, { variant: 'error' });
      }
  };

    const  handleDrop = useCallback(
        async (acceptedFiles: File[]) =>  {
            const file = acceptedFiles[0];

            const base64 = await convertBase64(file);

            if (file) {
                // @ts-ignore
                setValue('avatar', base64, { shouldValidate: true });
            }
        },
        [setValue]
    );


    const convertBase64 = (file:any) => new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    });




    return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3 }}>



            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatar"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Permitidos formatos *.jpeg, *.jpg, *.png, *.gif
                    <br /> de tamanho máximo {fData(3145728)}
                  </Typography>
                }
              />
            </Box>


          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="nome" label="Nome" />
              <RHFTextField name="email" label="Email" disabled />
              <RHFTextField name="NumeroWhatsApp" label="WhatsApp" disabled />

              <RHFSelectBandeiras name="CodigoPais" />


                <RHFSelect native name="estado" label="Estado"  >
                <option value="" disabled><></></option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="EX">Estrangeiro</option>

                </RHFSelect>


              <RHFTextField name="cidade" label="Cidade" />
              <RHFTextField name="CpfOrPassaporte" label="CPF" />

                <RHFTextField
                    name="Biografia"
                    placeholder="Crie sua biografia (Até 190 caracteres)"
                    multiline
                    rows={5}
                    maxRows={5}
                    fullWidth
                    inputProps={{ maxLength: 190 }}

                />


            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Criar Usuário' : 'Salvar Alterações'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
