import * as Yup from 'yup';
import {useEffect, useState, useCallback, useMemo} from 'react';
import {Divider, styled, Tabs,Radio ,RadioGroup ,FormControlLabel ,FormLabel , Tab, IconButton,Button,Link,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {useSnackbar} from "notistack";
import { LoadingButton } from '@mui/lab';
import axios from "../../../utils/axios";
import ChatNavItem from "../chat/nav/ChatNavItem";
import ProfilePicture from "./img/pessoa_call.png";
import { CustomFile } from '../../../components/upload';
import './Perfil.css';

import capa1 from "./img/capa1.jpg";
import capa2 from "./img/capa2.jpg";
import capa3 from "./img/capa3.jpg";
import capa4 from "./img/capa4.jpg";
import capa5 from "./img/capa5.jpg";
import capa6 from "./img/capa6.jpg";

import assunto1 from "./img/depressão.webp";
import assunto2 from "./img/ansiedade.webp";
import assunto3 from "./img/luto.webp";
import assunto4 from "./img/relacionamento.webp";
import assunto5 from "./img/financeiro.webp";
import assunto6 from "./img/Novo Projeto.webp";
import {RHFUploadAvatar} from "../../../components/hook-form/RHFUpload";
import FormProvider, {RHFCheckbox} from '../../../components/hook-form';
import {fData} from "../../../utils/formatNumber";
import {useAuthContext} from "../../../auth/useAuthContext";

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

interface FormValuesProps  {
    avatarUrl: CustomFile | string | null;
}

export default function PersonalizarPerfil() {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();

    const [checked, setChecked] = useState<any>([]);
    const [capa, setcapa] = useState<any>(1);
    const [avatar, setavatar] = useState<any>('');
    const [statusenvio, setstatusenvio] = useState<any>(false);




    useEffect(() => {

              if(user?.capa){
                  setcapa(user?.capa)
              }

    }, [user]);




    //
    //
    //
    // const geAssutos= useCallback(async () => {
    //     try {
    //         const response = await axios.post(`/areadoatendente/perfil/getassuntos`);
    //
    //         const {assuntos} = response.data;
    //
    //         setChecked(assuntos);
    //
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }, []);
    //
    // // @ts-ignore
    // useEffect( () => {
    //
    //     geAssutos()
    //
    // },[geAssutos]);
    
    

    const defaultValues = useMemo(
        () => ({
            avatarUrl: user?.avatar || null
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user]
    );




    // Add/Remove checked item from list
    const handleCheck = (event:any) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            if(checked.indexOf(event.target.value) !== -1){
                updatedList.splice(checked.indexOf(event.target.value), 1);
            }else{
                // @ts-ignore
                updatedList = [...checked, event.target.value];
            }

        } else {
            // @ts-ignore
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);

    };
    console.log(avatar);
    const NewUserSchema = Yup.object().shape({
        avatarUrl: Yup.mixed().required('Avatar is required'),
    });



    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const handleDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('avatarUrl', newFile, {shouldValidate: true});
                const base64 = await convertBase64(newFile);
                setavatar(base64);
            }
        },
        [setValue]
    );

    const onSubmit = async (data: FormValuesProps) => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            enqueueSnackbar('Criado com sucesso!');

            console.log('DATA', data);
        } catch (error) {
            console.error(error);
        }
    };



    const enviar = async (data:any) => {
        setstatusenvio(true);
        try {
            const response = await axios.post(`/areadoatendente/perfil/personalizar`, {
                checked,
                capa,
                avatar
            });

            enqueueSnackbar(`${response.data.message}`, {variant: 'success'});
             setstatusenvio(false);
        } catch (error) {
            setstatusenvio(false);
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
        }
    };


    const convertBase64 = (file:any) => new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    });

    // @ts-ignore
    return (
            <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Item className='cardEditarPerfil'>
                                <div className='FotoEscolhaPerfil'>
                                    <Box sx={{ mb: 5 }}>
                                        <RHFUploadAvatar
                                            name="avatarUrl"
                                            maxSize={3145728}
                                            onDrop={handleDrop}
                                            helperText={
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        mt: 2,
                                                        mx: 'auto',
                                                        display: 'block',
                                                        textAlign: 'center',
                                                        color: 'text.secondary',
                                                    }}
                                                >
                                                    Formatos permitidos *.jpeg, *.jpg, *.png, *.gif
                                                    <br/>
                                                     tamanho maximo of {fData(3145728)}

                                                </Typography>
                                            }
                                        />
                                    </Box>



                                        
                                </div>
                            </Item>

                            <Item className='cardEditarPerfil'>
                                <Typography className='TituloCardsPersonalizarPerfil'>Defina uma imagem de capa</Typography>
                                
                                <Grid container spacing={2}  direction="row">
                                            <Grid item xs={6} md={2}>
                                                <Item className='OpcoesFotosCapa'>
                                                    <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                                    <label htmlFor="capa1">
                                                        <img src={capa1} alt='Capa 1' className='FotosCapa'/>
                                                    </label>
                                                <input type="radio" name='opcoesCapa' id="capa1" checked={capa === 1} onChange={()=>{ setcapa(1)}} />
                                                </Item>
                                            </Grid>

                                            <Grid item xs={6} md={2}>
                                                <Item className='OpcoesFotosCapa'>
                                                    <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                                    <label htmlFor="capa2">
                                                        <img src={capa2} alt='Capa 2' className='FotosCapa'/>
                                                    </label>
                                                <input type="radio" name='opcoesCapa'  id="capa2" checked={capa === 2} onChange={()=>{ setcapa(2)}} />
                                                </Item>
                                            </Grid>

                                            <Grid item xs={6} md={2}>
                                                <Item className='OpcoesFotosCapa'>
                                                    <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                                    <label htmlFor="capa3">
                                                        <img src={capa3} alt='Capa 3' className='FotosCapa'/>
                                                    </label>
                                                <input type="radio" name='opcoesCapa'  id="capa3" checked={capa === 3} onChange={()=>{ setcapa(3)}} />
                                                </Item>
                                            </Grid>

                                            <Grid item xs={6} md={2}>
                                                <Item className='OpcoesFotosCapa'>
                                                    <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                                    <label htmlFor="capa4">
                                                        <img src={capa4} alt='Capa 4' className='FotosCapa'/>
                                                    </label>
                                                <input type="radio" name='opcoesCapa'  id="capa4" checked={capa === 4} onChange={()=>{ setcapa(4)}} />
                                                </Item>
                                            </Grid>

                                            <Grid item xs={6} md={2}>
                                                <Item className='OpcoesFotosCapa'>
                                                    <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                                    <label htmlFor="capa5">
                                                        <img src={capa5} alt='Capa 5' className='FotosCapa'/>
                                                    </label>
                                                <input type="radio" name='opcoesCapa'  id="capa5" checked={capa === 5}  onChange={()=>{ setcapa(5)}} />
                                                </Item>
                                            </Grid>

                                            <Grid item xs={6} md={2}>
                                                <Item className='OpcoesFotosCapa'>
                                                    <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                                    <label htmlFor="capa6">
                                                        <img src={capa6} alt='Capa 6' className='FotosCapa'/>
                                                    </label>
                                                <input type="radio" name='opcoesCapa' id="capa6" checked={capa === 6} onChange={()=>{ setcapa(6)}} />
                                                </Item>
                                            </Grid>  
                                </Grid>
                            </Item>


                            <Item className='cardEditarPerfil'>
                                <Typography className='TituloCardsPersonalizarPerfil'>Selecione tópicos de interesse</Typography>

                                <Grid container spacing={2}  direction="row">
                                    <Grid item xs={6} md={2}>
                                        <Item className='OpcoesFotosCapa'>
                                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                            <label htmlFor="assunto1">
                                                <img src={assunto1} alt='assunto 1' className='FotosCapa'/>
                                            </label>
                                            <input type="checkbox" name='opcoesCapa'  value={1}   id="assunto1" onChange={handleCheck}  />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={6} md={2}>
                                        <Item className='OpcoesFotosCapa'>
                                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                            <label htmlFor="assunto2">
                                                <img src={assunto2} alt='assunto 2' className='FotosCapa'/>
                                            </label>
                                            <input type="checkbox" name='opcoesCapa' value={2}    id="assunto2" onChange={handleCheck} />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={6} md={2}>
                                        <Item className='OpcoesFotosCapa'>
                                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                            <label htmlFor="assunto3">
                                                <img src={assunto3} alt='assunto 3' className='FotosCapa'/>
                                            </label>
                                            <input type="checkbox" name='opcoesCapa' value={3}   id="assunto3"  onChange={handleCheck}/>
                                        </Item>
                                    </Grid>

                                    <Grid item xs={6} md={2}>
                                        <Item className='OpcoesFotosCapa'>
                                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                            <label htmlFor="assunto4">
                                                <img src={assunto4} alt='assunto 4' className='FotosCapa'/>
                                            </label>
                                            <input type="checkbox" name='opcoesCapa' value={4}   id="assunto4" onChange={handleCheck} />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={6} md={2}>
                                        <Item className='OpcoesFotosCapa'>
                                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                            <label htmlFor="assunto5">
                                                <img src={assunto5} alt='Assunto 5' className='FotosCapa'/>
                                            </label>
                                            <input type="checkbox" name='opcoesCapa' value={5} id="assunto5"   onChange={handleCheck}/>
                                        </Item>
                                    </Grid>

                                    <Grid item xs={6} md={2}>
                                        <Item className='OpcoesFotosCapa'>
                                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                            <label htmlFor="assunto6">
                                                <img src={assunto6} alt='Assunto 6' className='FotosCapa'/>
                                            </label>
                                            <input type="checkbox" name='opcoesCapa' value={6} id="assunto6"    onChange={handleCheck}/>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>

                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Item className='OpcoesFotosCapa'>
                            <LoadingButton
                                fullWidth
                                color="info"
                                size="large"
                                variant="contained"
                                loading={statusenvio}
                                onClick={enviar}
                            >
                               Salvar
                            </LoadingButton>
                        </Item>
                    </Grid>
                </Box>

            </FormProvider>
            


            </>
    );
}
