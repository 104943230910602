import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { CustomAvatar } from 'src/components/custom-avatar';
import {
  IconButton,
  Typography,
  Button,
  Grid,
  Divider,
  List,
  Dialog,
  Chip,
  AppBar,
  Toolbar,
  Paper,
} from '@mui/material';
import ToggleButton from 'src/components/settings/drawer/ToggleButton';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import BadgeStatus, { BadgeStatusValue } from '../../../../components/badge-status';

// utils
import axios from '../../../../utils/axios';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../../@types/chat';
// components
import Iconify from '../../../../components/iconify';
import { useAuthContext } from '../../../../auth/useAuthContext';
import Scrollbar from '../../../../components/scrollbar';

import '../Agendamento.css';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type Props = {
  FecharAgendamento: any;
};

export default function AgoraOuAgendar({ FecharAgendamento }: Props) {
  const [status, setStatus] = useState<BadgeStatusValue>('online');

  const theme = useTheme();

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const [open, setOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { user } = useAuthContext();

  return (
    <>
      

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="geralAgendamentoAtendenteOnOff"
        sx={{ px: 0, py: 0 }}
        maxWidth="md"
      >
        <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 1.5, px: 0 }}
      >
        <IconButton>
          <Iconify icon="eva:arrow-ios-back-outline" />
          <Typography variant="subtitle2">Voltar</Typography>
        </IconButton>

        <IconButton onClick={FecharAgendamento}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Grid>
        <Grid item xs={12} sx={{ p: 2.5 }}>
          <Item>
            <Typography>Leandro Manfroi</Typography>
          </Item>

          <Item>
            <CustomAvatar
              className="FotoPerfilAtendente"
              src={user?.avatar}
              alt={user?.nome}
              name={user?.nome}
              sx={{ cursor: 'pointer', width: 100, height: 100 }}
              BadgeProps={{
                badgeContent: <BadgeStatus status={status} />,
              }}
            />
            <Typography variant="h3" mt={1.5} gutterBottom>
              Leandro Manfroi alterou o atendimento agendado com você
            </Typography>
          </Item>

          <Item>
            <Typography className="sugestaoDoAtendenteHorarioTitulo">
              Sugestão do atendente:
            </Typography>
            <div className="sugestaoDoAtendenteHorario">
              <Typography>10 Junho (Segunda)</Typography>
              <Chip variant="outlined" label="11:00" />
            </div>
          </Item>
        </Grid>

        <Grid item xs={12} sx={{ p: 2.5 }}>
          <Item>
            <Button
              fullWidth
              className="btnAgendamento"
              sx={{ p: 1.5 }}
              variant="contained"
              color="success"
            >
              Eu aceito
            </Button>
            <Button
              fullWidth
              className="btnAgendamento"
              sx={{ p: 1.5 }}
              variant="contained"
              color="primary"
            >
              Sugerir outra data e hora
            </Button>
            <Button
              fullWidth
              className="btnAgendamento"
              sx={{ p: 1.5 }}
              variant="outlined"
              color="error"
            >
              Cancelar agendamento
            </Button>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
