import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import PersonalizarPerfil from "../sections/@dashboard/Perfil/PersonalizarPerfil";



export default function PersonalizarPerfilPage() {
  return (
    <>
      <Helmet>
        <title>Personalizar Perfil do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil</title>
      </Helmet>

      <PersonalizarPerfil  />
    </>
  );
}
