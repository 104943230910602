import * as React from 'react';
import {CssBaseline, Box, Container, Typography, Tooltip, Button, Link, Grid, IconButton, Paper} from "@mui/material";
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';


import "./notifica.css";
// @mui
import {styled} from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle
import notificacaoPagamento from '../img/notificacao_pagamento.png'
import useResponsive from "../../../../hooks/useResponsive";
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
import anjoChegou from "../../SalaEspera/img/anjoChegou.webp";
// eslint-disable-next-line import/no-cycle

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


// ----------------------------------------------------------------------
type props = {
    fecharmodal: any,
    dados: any,
};


export default function NotificaAnjoChegou({fecharmodal,dados}:props) {

    const navigate = useNavigate();
    const irParaChat = ()=>{
        fecharmodal();
        navigate(PATH_DASHBOARD.chat.view(dados.tokenConversa),{replace:true});
    }

    const isDesktop = useResponsive('up', 'lg');

    return (
        <>

        {isDesktop ? (
            <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{height: '100vh'}}
            >
                <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                >
                    <div className="CardPixDesktop">
                        <Grid container justifyContent="space-between">
                            
                        {/* <IconButton onClick={fecharmodal}> */}
                        {/*        <Iconify icon="eva:arrow-ios-back-outline" /> */}
                        {/*    </IconButton> */}
                        {/*    <IconButton onClick={fecharmodal}> */}
                        {/*        <Iconify icon="eva:close-fill" /> */}
                        {/*    </IconButton> */}
                            
                        </Grid>

                        <Grid item container justifyContent="center">
                            
                    
                            <Item sx={{display: 'flex', flexDirection: 'column'}}>
                                <img alt="Boleto Gerado" width="300px" src={anjoChegou}/>
                                <Typography className="TextoTituloDialogNotificacaoPagamento"><Iconify className='IconCheckAnjoChegouSalaDeEspera' icon="eva:checkmark-circle-2-fill"/> Seu atendente já chegou</Typography>
                                <Typography className="TextoSubTituloDialogNotificacaoPagamento">
                                Clique no botão para encontrá-lo
                                </Typography>

                               

                                <Button variant="contained" onClick={irParaChat} sx={{py: 1.5, mt: 1.5}}  fullWidth>Ir para o Chat</Button>
                            </Item>
                        </Grid>


                    </div>
                    </Grid>
            </Grid>
        ) : (
            <>
            <CssBaseline/>
            <Container maxWidth="xl" className='GlobalChamadaEncerrada'>
                <div className='DialogPaddingAnjoChegouSaladeEspera'>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        {/* <Item> */}
                        {/*    <IconButton */}
                        {/*        edge="start" */}
                        {/*        color="inherit" */}
                        {/*        aria-label="close" */}
                        {/*        onClick={fecharmodal} */}
                        {/*    > */}
                        {/*        <Iconify icon="eva:chevron-left-fill"/> */}
                        {/*    </IconButton> */}
                        {/* </Item> */}

                        <Item>
                            {/* <IconButton */}
                            {/*    edge="start" */}
                            {/*    color="inherit" */}
                            {/*    aria-label="close" */}
                            {/*    onClick={fecharmodal} */}
                            {/* > */}
                            {/*    <Iconify icon="eva:close-fill"/> */}
                            {/* </IconButton> */}
                        </Item>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className='MainDialogSaladeEsperaAnjoChegou'
                    >

                        <img src={anjoChegou} className='ImagemSalaDeEsperaAnjoChegou' alt='Aguarde'/>

                        <Typography className='TituloSalaDeEsperaAnjoChegou'> <Iconify className='IconCheckAnjoChegouSalaDeEspera' icon="eva:checkmark-circle-2-fill"/> Seu atendente já chegou </Typography>
                        <Typography className='SubTituloSalaDeEsperaAnjoChegou'> Clique no botão para encontrá-lo </Typography>
                    </Grid>

                    <div className='FooterButtonSalaDeEsperaAnjoChegou'>
                        <Button className='ButtonSalaDeEsperaAnjoChegou' onClick={irParaChat}>Ir para o Chat</Button>
                    </div>

                </div>
            </Container>
            </>
        )}


            

            </>
    );
}