import * as React from 'react';
import {CssBaseline, Box, Container, Typography, Tooltip, Button, Link } from "@mui/material";
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';


import "./notifica.css";
// @mui
import {styled} from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle
import notificacaoPagamento from '../img/notificacao_pagamento.png'

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
// eslint-disable-next-line import/no-cycle




// ----------------------------------------------------------------------
type props = {
    fecharmodal: any,
    dados: any,
};


export default function NotificaPagamento({fecharmodal,dados}:props) {


    const navigate = useNavigate();

    const iniciarchamada = ()=>{
        fecharmodal();
        navigate(PATH_DASHBOARD.salaespera(dados.tokenConversa),{replace:true});
    }





    return (
        <>
            <CssBaseline/>
            <Container maxWidth="xl" className='GlobalChamadaEncerrada'>
                <Box sx={{bgcolor: '#fff', height: '100vh', width: '100vw'}}>

                <div className='containerInterno'>

                    <div className='InfosChamada'>                    
                        <img src={notificacaoPagamento} alt="" width="280px"/>
                    </div>

                    <div className='InfosChamadaEncerrada'>                    
                        <Typography className='DesejaEncerrarChamada'> 
                            <Iconify icon="eva:checkmark-circle-2-fill" className='iconButtonNotificationGreen'/>
                            Pagamento aprovado!
                        </Typography>
                        <div className='textoChamadaEncerrada'>Seu pagamento foi aprovado, saiba mais sobre suas transições na página Financeiro</div>
                    </div>

                        <button type='button' className='buttonNotification' onClick={iniciarchamada}>
                            <Iconify icon="eva:checkmark-fill" className='iconButtonNotification'/>  
                            Continuar
                        </button>

                    
                </div>

                </Box>
            </Container>

            </>
    );
}