import ReactGA from "react-ga4";


export function criarevento( evento: string) {

    ReactGA.initialize("G-P8NQH0LJQJ");
        ReactGA.event({
            category: "heroi",
            action: evento,
            label: evento, // optional
            value: 99, // optional, must be a number
            nonInteraction: false, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
        });


}
export function criarurl( pagina: string) {
    ReactGA.initialize("G-P8NQH0LJQJ");
    ReactGA.send({ hitType: "pageview", page: `/${pagina}`, title: "teste titulo" });
}


export function criarurlads( pagina: string) {
    ReactGA.initialize("AW-696299330");
    ReactGA.send({ hitType: "pageview", page: `/${pagina}`, title: "teste titulo" });
}

