import {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled, Theme } from '@mui/material/styles';
import {
    Grid,
    Box,
    Stack,
    Drawer,Chip,
    IconButton,
    IconButtonProps,
    Typography,
    Tooltip,
    Divider,
    Button,
    CircularProgress,
    List,Menu,
    MenuItem, Select
} from '@mui/material';
import Iconify from 'src/components/iconify';

import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { CustomAvatar, CustomAvatarGroup } from '../../../../components/custom-avatar';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// utils
import axios from '../../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';
// @types

import '../Agendamentos.css';

// ----------------------------------------------------------------------

import profile from '../img/img_profile.webp';



// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 96;




export default function ItemAgendamentos() {
  
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useResponsive('up', 'md');

    const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [status, setStatus] = useState<BadgeStatusValue>('Offline');

    return (
        <>
            <div className='CardItemAgendamentos' style={{marginBottom: '16px'}}>
                <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                >
                    <div style={{display: 'flex'}}>
                        <CustomAvatar
                            src={profile}
                            sx={{ width: 48, 
                                height: 48, 
                                mr: 1.5
                             }}
                        />

                        <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        >
                            <Typography variant='h6'>Nome</Typography>
                            <Typography sx={{mb: 1}}>tipo de atendente</Typography>

                            <div style={{display: 'flex'}}>
                                <Chip label='DD/MM/YY' variant="outlined" color='primary' icon={<Iconify icon='eva:calendar-outline' sx={{mr: 0.5}} />} sx={{mr: 1}} />
                                <Chip label='HH:MM' variant="outlined" color='primary' icon={<Iconify icon='eva:clock-outline' sx={{mr: 0.5}} />} sx={{mr: 1}} />
                            </div>
                        </Grid>
                    </div>

                    <div>
                    <Button variant='contained' color='primary'>Chat</Button>

                    <Button
                        className="BotaoMais_AtendentesHistoricoAcolhimentoRecorrente"
                        aria-controls={anchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Iconify icon="eva:more-vertical-fill" />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem >Avaliar</MenuItem>
                        <MenuItem >Denunciar</MenuItem>
                        <MenuItem >Ver Perfil</MenuItem>
                        <MenuItem >Alterar data/hora</MenuItem>
                    </Menu>
                    </div>

                </Grid>
            </div>
            
        </>
    )
}
