import { Helmet } from 'react-helmet-async';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// sections
import { Conversar } from 'src/sections/@dashboard/Conversar';



export default function AgendamentoPage() {

  const {idAtendente = ''} = useParams();

  return (
    <>
      <Helmet>
        <title>Conversar - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      
        <Conversar atendenteid={idAtendente} />
                    
    </>
  );
}
