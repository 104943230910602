import {format, getTime, formatDistanceToNow, formatDistanceToNowStrict} from 'date-fns';
import ptBR from "date-fns/locale/pt-BR/index";

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd/MMM/yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}
export function dataHoracertachat(date: InputValue, newFormat?: string) {
    const fm = newFormat || 'yyyy-MM-dd HH:mm:ss';

    return date ? format(new Date(date), fm, { locale: ptBR }) : '';
}
export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}
export function timedochat(date: any) {


    const dateatual = new Date();


    const [datas, horas] = date.split(' ');
    const [ano, mes,dia] = datas.split('-');
    const [hora, min,seg] = horas.split(':');


    const data = new Date(ano, mes -1, dia, hora, min, seg);



    // @ts-ignore
    const diffInMs   =   dateatual - data ;


    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    const diffInHoras = diffInMs / (1000 * 60 * 60 );
    const diffInMin = diffInMs / (1000 * 60  );
    const diffInsegundos = diffInMs / (1000  );

    if(Math.floor(diffInDays) >= 1){
        return `há ${ Math.floor(diffInDays)} dias`
    }

    if(Math.floor(diffInHoras) >= 1){
        return `há ${ Math.floor(diffInHoras)} horas`
    }

    if(Math.floor(diffInMin) >= 1){
        return `há ${ Math.floor(diffInMin)} minuto`
    }

    if(Math.floor(diffInsegundos) >= 30){
        return `há ${ Math.floor(diffInsegundos)} segundos`
    }

    if(Math.floor(diffInsegundos) >= 1){
        return 'Enviado agora';
    }



    return `${dia}/${mes}/${ano} ${hora}:${min}`;


    const tempo = formatDistanceToNow(new Date(date));

    if(tempo ===  'less than a minute'){
        return 'Enviado agora';
    }

    return  formatDistanceToNowStrict(new Date(date), {
        addSuffix: true,
        locale: ptBR,
    });

}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fDataHoracerta(date: InputValue, newFormat?: string) {
    const fm = newFormat || 'dd/MM/yyyy HH:mm';

    return date ? format(new Date(date), fm) : '';
}
