import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import Atendimento from "../sections/@dashboard/Atendimento/Atendimento";
import Financeiro from "../sections/@dashboard/Financeiro/Financeiro";



export default function FinanceiroPage() {
  return (
    <>
      <Helmet>
        <title> Financeiro do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Financeiro  />
    </>
  );
}
