import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Divider, IconButton,Button, List, MenuItem, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Typography} from "@mui/material";
import Iconify from 'src/components/iconify';

import "./DialogMaisInfoPacote.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type props = {
    openMaisInfosPacote: any;
    handleClickOpenMaisInfosPacote: any;
    handleCloseMaisInfosPacote: any;
    dados: any;
};


export default function DialogMaisInfoPacote({openMaisInfosPacote,handleClickOpenMaisInfosPacote, handleCloseMaisInfosPacote,dados}:props) {
  
    const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

  return (
   
      <Dialog
      fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openMaisInfosPacote}
        onClose={handleCloseMaisInfosPacote}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <DialogContent sx={{py:3}}>
          <DialogContentText id="alert-dialog-description">

            <Typography variant='h4' color='primary'>{dados.nomeatendente}</Typography>
            <Rating size="small" className="AvaliacaoAtendente" name="read-only" value={dados.estrelas} readOnly />

            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{mt: 1}}
            >


                <Grid className='ItemDetalhesPacote' item xs>
                    <Iconify icon='eva:message-circle-outline' />
                    <Typography variant='subtitle2'>{dados.atedimentos} Atendimentos feitos</Typography>
                </Grid>

                <Grid className='ItemDetalhesPacote' item xs>
                    <Iconify icon='eva:calendar-outline' />
                    <Typography variant='subtitle2'>Atendente Eyhe desde: {dados.datacriacao} </Typography>
                </Grid>
   
            </Grid>

            <Typography variant='h5' sx={{mt: 3, mb: 1}}  color='primary'>Biografia</Typography>
            <Typography variant='subtitle2'>{dados.biografia}</Typography>



            <Grid container spacing={2} sx={{mt: 2}}>
              <Grid item xs={12} md={4}>
                <Item className='ItemDetalhesPacoteLista'>
                  <Iconify icon='eva:checkmark-circle-2-outline' />
                  <Typography variant='subtitle1'>Vantagem 1</Typography>
                </Item>

                <Item className='ItemDetalhesPacoteLista'>
                  <Iconify icon='eva:checkmark-circle-2-outline' />
                  <Typography variant='subtitle1'>Vantagem 2</Typography>
                </Item>

                <Item className='ItemDetalhesPacoteLista'>
                  <Iconify icon='eva:checkmark-circle-2-outline' />
                  <Typography variant='subtitle1'>Vantagem 3</Typography>
                </Item>

                <Item className='ItemDetalhesPacoteLista'>
                  <Iconify icon='eva:checkmark-circle-2-outline' />
                  <Typography variant='subtitle1'>Vantagem 4</Typography>
                </Item>
              </Grid>
            </Grid>


            

          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{background:'#f3f4f6'}}>
          <Button onClick={handleCloseMaisInfosPacote} variant='contained' autoFocus>
            Entendi
          </Button>
        </DialogActions>
      </Dialog>
    
  );
}