import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

// http://localhost/digital/eyhenew/eyheapi/public/api
// https://www.eyhe.com.br/eyhe5/api/public/api
// https://eyhe.in/eyhe5/api/public/
// https://api.eyhe.com.br/
const axiosInstance = axios.create({ baseURL: 'https://api.eyhe.com.br/api' });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Desculpe, aconteceu um erro, tente novamente mais tarde')
);

export default axiosInstance;
