import * as React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import Iconify from '../iconify/Iconify';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type Props = {
  setOpen: any;
  deletar: any;
  opendeletemodal: boolean;
};
export default function DeleteModal({ opendeletemodal, setOpen, deletar }: Props) {
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={opendeletemodal}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              Deseja Deletar?
            </Typography>

            <Button
              sx={{ p: 0, m: 0, position: 'relative', left: '20px' }}
              autoFocus
              color="inherit"
              onClick={handleClose}
            >
              <Iconify icon="eva:close-outline" />
            </Button>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja deletar ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deletar}
            variant="contained"
            sx={{ px: 1.5, py: 1 }}
            color="error"
            autoFocus
          >
            <Iconify icon="eva:trash-2-outline" sx={{ mr: 1 }} />
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
