import * as React from 'react';
import { m } from 'framer-motion';
import Dialog from '@mui/material/Dialog';
import { Helmet } from 'react-helmet-async';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import {Link as RouterLink, useParams} from 'react-router-dom';
// @mui
import { Button, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { PageNotFoundIllustration } from '../assets/illustrations';
import SalaVideo from "../sections/@dashboard/SalaVideo/SalaVideo";

const Transition = React.forwardRef((
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------
type props = {
    socket: any;
};
export default function ChatVideo({socket}:props) {






  return (
    <>
      <Helmet>
        <title> Chat por Video do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

        <Dialog
            fullScreen
            open
                scroll='body'
            TransitionComponent={Transition}
        >
            <SalaVideo />
        </Dialog>
    </>
  );
}
