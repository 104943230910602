import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import Atendimento from "../sections/@dashboard/Atendimento/Atendimento";



export default function AtendimentoPage() {
  return (
    <>
      <Helmet>
        <title>Lista de  Atendimentos do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Atendimento  />
    </>
  );
}
