import {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { CustomAvatar } from "src/components/custom-avatar";
import {
    IconButton,
    Typography,
    Button,
    Grid,
    Divider,
    List,
    Dialog,
    AppBar,
    Toolbar,
    Paper
} from '@mui/material';
import ToggleButton from 'src/components/settings/drawer/ToggleButton';

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../hooks/useResponsive';

import BadgeStatus, {BadgeStatusValue} from "../../../components/badge-status";

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import {useAuthContext} from "../../../auth/useAuthContext";
import Scrollbar from '../../../components/scrollbar';
import AtendenteOnOff from './componentesConversar/atendenteOnOff';
import './Conversar.css';


//

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  type props = {
      atendenteid: any;
};

export default function Conversar({atendenteid}:props) {

  const navigate = useNavigate();

    const [status, setStatus] = useState<BadgeStatusValue>('invisible');


     const handleClickHorariosAgendamento = () => {
      navigate(PATH_DASHBOARD.agendamento(atendenteid));
     }

     const handleClickFecharAgendamento = () => {
      navigate(PATH_DASHBOARD.dashfirst);
     }

     const handleClickPaginaAtendentes = () => {
      navigate(PATH_DASHBOARD.atendentes);
     }

     const irparapagamanto = () =>{
      navigate(PATH_DASHBOARD.pagamento(atendenteid));
  }

    const theme = useTheme();

    const isDesktop = useResponsive('up', 'md');

    const [open, setOpen] = useState(true);
    const [usera, setuser] = useState<any>({});
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const { user } = useAuthContext();
    useEffect(  () => {
        const fetchRepos2 = async () => {
            const response = await axios.get(`/areadoheroi/atendentepeloid/${atendenteid}`);

            const {atendente} = response.data;


            setuser(atendente);
            setStatus(atendente.StatusAtendimento);
        }
        fetchRepos2();

        sessionStorage.removeItem('dadosAgendamento');



    },[atendenteid]);

    return (
        <>
           
           <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            className='dialogAgendamento'
            >
                
                

              <AtendenteOnOff Agendar={handleClickHorariosAgendamento} status={status} Conversar={irparapagamanto} usera={usera} OutroAtendente={handleClickPaginaAtendentes} Voltar={handleClickFecharAgendamento} FecharAgendamento={handleClickFecharAgendamento} />
                    
                    

          </Dialog>


           

        </>
    )
}
