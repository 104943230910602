import {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { CustomAvatar } from "src/components/custom-avatar";
import {
    IconButton,
    Typography,
    Button,
    Grid,
    Divider,
    List,
    Dialog,
    AppBar,
    Toolbar,
    Paper
} from '@mui/material';
import ToggleButton from 'src/components/settings/drawer/ToggleButton';

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../hooks/useResponsive';

import BadgeStatus, {BadgeStatusValue} from "../../../components/badge-status";

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import {useAuthContext} from "../../../auth/useAuthContext";
import Scrollbar from '../../../components/scrollbar';

import './Agendamento.css';
import HorariosAgendamento from './componentesAgendamento/horariosAgendamento';
import MaisOpcoesHorariosAgendamento from './componentesAgendamento/maisOpcoesHorariosAgendamento';
import ConfirmarAlteracao from './componentesAgendamento/ConfirmarAlteracao';
import AgoraOuAgendar from './componentesAgendamento/agoraOuAgendar';
import ConfirmarAgendamento from './componentesAgendamento/ConfirmarAgendamento';

//

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  type props = {
      atendenteid: any;
};

export default function Agendamento({atendenteid}:props) {

  const navigate = useNavigate();

    const [status, setStatus] = useState<BadgeStatusValue>('Offline');
    const [usera, setuser] = useState<any>({});
    const [statusDoAgendamento, setStatusDoAgendamento] = useState('HorariosAgendamento');
    const [selectedHorario, setSelectedHorario] = useState('');
     // HorariosAgendamento - Primeira tela
     // MaisOpcoes - Segunda Tela
     // ConfirmarAgendamento
     // ConfirmarAlteracao
     // AgoraOuAgendar



     const handleClickHorariosAgendamento = () => {
      setStatusDoAgendamento('HorariosAgendamento')
     }

     const handleClickMaisOpcoes = () => {
      setStatusDoAgendamento('MaisOpcoes')
     }
     const voltartelaconfirma = () => {

         if(selectedHorario.includes("-")){
             setStatusDoAgendamento('MaisOpcoes');
         }else{
             setStatusDoAgendamento('HorariosAgendamento');
         }

     }

     const handleClickConfirmarAgendamento = () => {
      setStatusDoAgendamento('ConfirmarAgendamento')
     }

     const handleClickConfirmarAlteracao = () => {
      setStatusDoAgendamento('ConfirmarAlteracao')
     }

     const handleClickAgoraOuAgendar = () => {
      setStatusDoAgendamento('AgoraOuAgendar')
     }

     const handleClickFecharAgendamento = () => {
      navigate(PATH_DASHBOARD.dashfirst);
     }

     const handleClickPaginaAtendentes = () => {
      navigate(PATH_DASHBOARD.atendentes);
     }

     const irparapagamanto = () =>{
      console.log('sasasass');
      navigate(PATH_DASHBOARD.pagamento(atendenteid));
  }

    const theme = useTheme();

    const isDesktop = useResponsive('up', 'md');

    const [open, setOpen] = useState(true);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const { user } = useAuthContext();

    useEffect(  () => {
        const fetchRepos2 = async () => {
            const response = await axios.get(`/areadoheroi/atendentepeloid/${atendenteid}`);

            const {atendente} = response.data;


            setuser(atendente);
            setStatus(atendente.StatusAtendimento);
        }
        fetchRepos2();
        sessionStorage.removeItem('dadosAgendamento');
    },[atendenteid]);



    return (
        <>
           
           <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            className='dialogAgendamento'
            >
                
                

                    {statusDoAgendamento === 'HorariosAgendamento' ? (<HorariosAgendamento status={status} selectedHorario={selectedHorario} setSelectedHorario={setSelectedHorario} usera={usera} MaisOpcoes={handleClickMaisOpcoes} Voltar={handleClickFecharAgendamento} ConfirmarAgendamento={handleClickConfirmarAgendamento} FecharAgendamento={handleClickFecharAgendamento} />) : ('')}
                    {statusDoAgendamento === 'MaisOpcoes' ? (<MaisOpcoesHorariosAgendamento ConfirmarAgendamento={handleClickConfirmarAgendamento} setSelectedHorario={setSelectedHorario}  usera={usera} Voltar={handleClickHorariosAgendamento} FecharAgendamento={handleClickFecharAgendamento}/>) : ('')}
                    {statusDoAgendamento === 'ConfirmarAgendamento' ? (<ConfirmarAgendamento selectedHorario={selectedHorario} AlterarAgendamento={voltartelaconfirma} Voltar={handleClickMaisOpcoes}  usera={usera}  FecharAgendamento={handleClickFecharAgendamento} />) : ('')}
                    {statusDoAgendamento === 'AgoraOuAgendar' ? (<AgoraOuAgendar FecharAgendamento={handleClickFecharAgendamento} />) : ('')}
                
                    

          </Dialog>


           

        </>
    )
}
