import { Helmet } from 'react-helmet-async';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// sections
import {Agendamento} from "../sections/@dashboard/Agendamento";



export default function AgendamentoPage() {

  const {idAtendente = ''} = useParams();

  return (
    <>
      <Helmet>
        <title>Agendamentos do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      
        <Agendamento atendenteid={idAtendente} />
                    
    </>
  );
}
