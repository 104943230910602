import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import {Atendetentes} from "../sections/@dashboard/Atendentes";
import {Cadastro} from "../sections/auth/Cadastro";
import Cadastrogoogleform from "../sections/auth/Cadastro/Cadastrogoogleform";
import {useAuthContext} from "../auth/useAuthContext";



export default function CadastregoogleformPage() {
    const {  user} = useAuthContext();
  return (
    <>
      <Helmet>
        <title>Cadastre-se como Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Cadastrogoogleform user={user} />
    </>
  );
}
