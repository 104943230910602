import * as React from 'react';

import {
    Divider,
    Tabs,
    Tab,
    IconButton,
    Button,
    TextField,
    InputLabel,
    Slide,
    DialogProps,
    List,
    MenuItem,
    FormControl,
    OutlinedInput,
    Alert,
    InputAdornment,
    Paper,
    Box,
    Grid,
    Badge,
    Avatar,
    Select,
    Stack,
    Rating,
    Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    MobileStepper ,
    
  } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import { styled, useTheme  } from '@mui/material/styles';


import { TransitionProps } from '@mui/material/transitions';
import Iconify from 'src/components/iconify';

import LogoEyhe from './materiais_media/logo_azul.png'
import OrientacaoStep1 from './materiais_media/Eyhe_SEJABEMVINDO_step.png';
import OrientacaoStep2 from './materiais_media/eyhe_step_anjos.png';
import OrientacaoStep3 from './materiais_media/Eyhe_PROFISSIONAIS_Step.png';
import OrientacaoStep4 from './materiais_media/Eyhe_Parcelamento_Reembolso_step.png';
import OrientacaoStep5 from './materiais_media/Eyhe_ESCOLHAUMATENDENTE_step.png';

type Props = {
    openDialogOrientacoes: any;
    handleCloseDialogOrientacoes: any;
};


function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="down" ref={ref} {...props} />;
  }

// Em seguida, use React.forwardRef para criar um componente de transição reutilizável
const ForwardedTransitionOrientacoes = React.forwardRef(Transition);


const steps = [
    {
      logo: LogoEyhe,
      ilustrarion: OrientacaoStep1,
      label: 'Seja bem-vindo ao Eyhe - Plataforma de Suporte Emocional.',
      description: `Aqui você vai encontrar pessoas e profissionais comprometidos com o seu bem-estar para 
      conversar sem julgamentos. Nosso propósito é ajudar você a superar seus desafios.`,
    },
    {
      logo: LogoEyhe,
      ilustrarion: OrientacaoStep2,
      label: 'Quem são os Anjos Eyhe?',
      description:"Os anjos Eyhe são pessoas que possuem histórias de vida vencedoras. Pessoas que já superaram desafios e agora estão dispostas a compartilhar suas experiências e acolher outras pessoas através do exemplo e da empatia. A primeira conversa com um Anjo Eyhe é sempre gratuita.",
    },
    {
      logo: LogoEyhe,
      ilustrarion: OrientacaoStep3,
      label: 'Profissionais Eyhe',
      description: `Os profissionais Eyhe são psicólogas(os), psicanalistas, terapeutas, coachs, todos à sua disposição
      para promover através de sessões profissionais o seu bem-estar.
      Escolha o profissional que mais se encaixa com a sua personalidade ou necessidade e inicie uma sessão ou
      faça um agendamento.`,
    },
    {
        logo: LogoEyhe,
        ilustrarion: OrientacaoStep4,
        label: 'Parcelamento e reembolso',
        description: `Você pode parcelar as sessões e conversas no seu cartão de crédito de forma fácil e rápida. 
        Caso algum problema aconteça, você pode solicitar o reembolso do valor pago.`,
      },
      {
        logo: LogoEyhe,
        ilustrarion: OrientacaoStep5,
        label: 'Escolha um atendente Eyhe para conversar',
        description: (
          <>
          Agora você será direcionado para o dashbord do herói, onde a lista de atendentes Eyhe está disponível.
          Escolha o atendente que você considerar o mais adequado para este momento e tenha uma ótima conversa.
          <br/>
          Utilize o cupom de desconto <span style={{background: '#078DEE', color: '#ffffff', padding: '4px 10px', borderRadius: '8px'}}> EYHE10</span> para ter 10% de desconto em sua próxima conversa.
          </>
        )
      },
  ];



export default function DialogOrientacoes({openDialogOrientacoes, handleCloseDialogOrientacoes}:Props) {
  
    const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }; 

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      
      <Dialog
        open={openDialogOrientacoes}
        onClose={handleCloseDialogOrientacoes}
        TransitionComponent={ForwardedTransitionOrientacoes}
      >

            <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{height: 'auto', p: 3}}
            >
                    
                <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center">
                    <img src={steps[activeStep].ilustrarion}  height={140} alt='Ilutration' />
                    <Typography variant='h5' sx={{mt: 3, mb: 1, textAlign: 'center'}}>{steps[activeStep].label}</Typography>
                    <Typography variant='subtitle1' sx={{textAlign: 'center'}}> {steps[activeStep].description} </Typography>
                </Grid>

                
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        activeStep === 4 ? (
                            <Button  variant="contained" onClick={handleCloseDialogOrientacoes} sx={{ ml: 1 }}>
                              Finalizar 
                              <Iconify sx={{ml: 0.5}} icon='eva:checkmark-circle-outline' />
                            </Button>
                          ) : (
                            <Button
                            
                            variant='outlined'
                            onClick={handleNext}
                            sx={{ml: 1}}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Próximo
                            {theme.direction === 'rtl' ? (
                            <Iconify icon='eva:chevron-left-fill' />
                            ) : (
                            <Iconify icon='eva:chevron-right-fill' />
                            )}
                        </Button>
                          )
                   
                    }
                    backButton={
                    <Button  onClick={handleBack} sx={{mr: 1}} variant='outlined' disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                        <Iconify icon='eva:chevron-right-fill' />
                        ) : (
                        <Iconify icon='eva:chevron-left-fill' />
                        )}
                        Anterior
                    </Button>
                    }
                />
            </Grid>
        
      </Dialog>
    </>
  );
}