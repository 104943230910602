import { Dialog,DialogTitle , ToggleButtonGroup,ToggleButton, Grid, DialogContent,ListItemText, Box, Rating, useMediaQuery ,ListItem,List,Tooltip, Divider, AppBar , Toolbar ,IconButton  ,Slider,Slide, Button, TextField, Tabs, Tab, InputAdornment, Paper, Typography} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import React, {useState} from "react";
import useResponsive from 'src/hooks/useResponsive';
import {styled, useTheme} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import FiltrarProfissional from "src/components/filtrar-profissional/FiltrarProfissional";
import {useSettingsContext} from "../../../../components/settings";
import {useAuthContext} from "../../../../auth/useAuthContext";
import {PATH_DASHBOARD} from "../../../../routes/paths";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";
import "./FiltrarAtendente.css";
import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";
import axios from "../../../../utils/axios";

import iconAtendente1 from "./img/love.svg"
import iconAtendente2 from "./img/psychology.svg"
import iconAtendente4 from "./img/terapia.png"
import iconAtendente5 from "./img/coach.png"


import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import FiltroAvancadoDesktop from "../../Dashfirst/componentesDashboardFirst/FiltroAvancado";
import FiltroTipoAtendenteMobile from "../../Dashfirst/componentesDashboardFirst/FiltroTipoAtendenteMobile";
import FiltroAssuntoMobile from "../../Dashfirst/componentesDashboardFirst/FiltroAssuntoMobile";

const icon = <Iconify icon="eva:square-outline"/>;
const checkedIcon = <Iconify icon="eva:checkmark-square-2-fill"/>;


const labels: { [index: string]: string } = {
    0.5: '',
    1: '',
    1.5: '',
    2: '',
    2.5: 'Ok',
    3: 'Bom',
    3.5: 'Muito bom',
    4: 'Excelente',
    4.5: 'Espetacular',
    5: 'Atendimento Perfeito',
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  function valuetext(value: number) {
    return `${value}°C`;
  }

  const minDistance = 18;


type props = {
    setassunto: any,
    setquery: any,
    settipoprofissional: any,
    setavaliacao: any,
    setpreco: any,
    setpage: any,
};



export default function FiltrarAtendente({setassunto,setquery,settipoprofissional,setavaliacao,setpreco, setpage}:props) {
    const isDesktop = useResponsive('up', 'lg');

    const [value, setValue] = React.useState<number | null>(0);
  const [hover, setHover] = React.useState(-1);

    const [busca, setBusca] = useState(true);
    const [valuequery, setvaluequery] = useState('');

    const [dialogOpenFiltroAvancado, setDialogOpenFiltroAvancado] = useState(false);

    const handleOpenDialogFiltroAvancado = () => {
      setDialogOpenFiltroAvancado(true);
    };
  
    const handleCloseDialogFiltroAvancado = () => {
      setDialogOpenFiltroAvancado(false);
    };

    const NovaBusca = () => {
        setBusca(true);
    }
    const navigate = useNavigate();


    const [valueProfissional, setValueProfissional] = React.useState('');
    const handleProficional = (event: React.SyntheticEvent, newValue: any) => {
        setValueProfissional(newValue);
        settipoprofissional(newValue);
    };

    const [valueAssunto, setValueAssunto] = React.useState('');
    const handleAssunto = (event: React.SyntheticEvent, newValue: any) => {
        setValueAssunto(newValue);
        setassunto(newValue);
    };

    const [valueGenero, setValueGenero] = React.useState('');
    const handleChange3 = (event: React.SyntheticEvent, newValue: any) => {
        setValueGenero(newValue === valueGenero ? '' : newValue);
    };

    const handleClean = () => {
        setValueProfissional('');
        setValueAssunto('');
        setValueGenero('');
        setValue1([20, 80]);
        setValue2([18, 100]);
    }

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        Buscar()
    };

    const [value1, setValue1] = React.useState<number[]>([20, 80]);

   

    const handleChangePreco = (
      event: Event,
      newValue: number | number[],
      activeThumb: number,
    ) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (activeThumb === 0) {
        setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
      } else {
        setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
      }
    };


    const [value2, setValue2] = React.useState<number[]>([18, 65]);

    const handleChangeIdade = (
      event: Event,
      newValue: number | number[],
      activeThumb: number,
    ) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (activeThumb === 0) {
        setValue2([Math.min(newValue[0], value2[1] - minDistance), value2[1]]);
      } else {
        setValue2([value2[0], Math.max(newValue[1], value2[0] + minDistance)]);
      }
    };

    const Buscar = () => {
        setBusca(false);
        setassunto(valueAssunto);
        setquery(valuequery);
        settipoprofissional(valueProfissional);
        setpreco(value1);
        setavaliacao(value);

        setpage(1);

    }

    const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
                setquery(event.target.value);
                setpage(1);
        } catch (error) {
            console.error(error);
        }
    };

    const irDash = async () => {
        navigate(PATH_DASHBOARD.dashfirst, { replace: true });
    };

    
    return (
        <>

        
            <div className="CardFiltrarAtendentePageAtendentes">
                { busca === true ? (
                <div id="cardFiltroAtendenteAtivo">
                    
                    {isDesktop ? (
                        <>
                        <div className="TipoDeProfissionalScroll">
                        <Typography className="SubTituloCardFiltroAtendente">Tipo de Profissional</Typography>

                        <ToggleButtonGroup
                            color="primary"
                            value={valueProfissional} 
                            onChange={handleProficional}
                            exclusive
                            aria-label="Tipo de Profissional"
                            >
                                <ToggleButton className="IconFiltroBuscaGenero" value="1"><img src={iconAtendente1} height="30px" alt="Icone Anjo" /> Anjo</ToggleButton>
                                <ToggleButton className="IconFiltroBuscaGenero" value="2"><img src={iconAtendente2} height="30px" alt="Icone Psicólogo" /> Psicólogo</ToggleButton>
                                <ToggleButton className="IconFiltroBuscaGenero" value="4"><img src={iconAtendente4} height="30px" alt="Icone Terapeuta" /> Terapeuta</ToggleButton>
                                <ToggleButton className="IconFiltroBuscaGenero" value="5"><img src={iconAtendente5} height="30px" alt="Icone Coach" /> Coach</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div className="AssuntoScroll">
                    <Typography className="SubTituloCardFiltroAtendente">Busca por Assunto</Typography>
                    

                      <ToggleButtonGroup
                    color="primary"
                    value={valueAssunto}
                    exclusive
                    onChange={handleAssunto}
                    aria-label="Profisionais"
                    className="FiltroProfissionais"
                    >
                        <ToggleButton value="17"> <img src="https://api.eyhe.com.br/img/filtrar-assuntos/Crying.svg" height="30px" alt="Icone Abuso Sexual" />  Abuso Sexual</ToggleButton>
                        <ToggleButton value="2"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Intrusive thought.svg" height="30px" alt="Icone Ansiedade" /> Ansiedade</ToggleButton>
                        <ToggleButton value="1"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Depression.svg" height="30px" alt="Icone Depressão" /> Depressão</ToggleButton>
                        <ToggleButton value="3"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Alzeimer.svg" height="30px" alt="Icone Luto" /> Luto</ToggleButton>
                        <ToggleButton value="7"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Mental Health.svg" height="30px" alt="Icone Problemas de Saúde" /> Problemas de saúde</ToggleButton>
                        <ToggleButton value="4"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Support System.svg" height="30px" alt="Icone Relacionamentos" /> Relacionamento</ToggleButton>
                        <ToggleButton value="18"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Emotion.svg" height="30px" alt="Icone Amor" /> Amor</ToggleButton>
                        <ToggleButton value="24"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Stress.svg" height="30px" alt="Icone Stress" /> Stress</ToggleButton>
                        <ToggleButton value="25"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Drugs Addict.svg" height="30px" alt="Icone Vícios" /> Vícios</ToggleButton>
                        <ToggleButton value="22"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Hypersex.svg" height="30px" alt="Icone Gênero" /> Gênero</ToggleButton>
                        <ToggleButton value="12"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Insecure.svg" height="30px" alt="Icone Insegurança" /> Insegurança</ToggleButton>
                        <ToggleButton value="21"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Phobia.svg" height="30px" alt="Icone Fobias" /> Fobias</ToggleButton>
                        <ToggleButton value="23"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Sleep.svg" height="30px" alt="Icone Insônia" /> Insônia</ToggleButton>
                        <ToggleButton value="19"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Autism.svg" height="30px" alt="Icone Autismo" /> Autismo</ToggleButton>
                        <ToggleButton value="20"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Bipolar.svg" height="30px" alt="Icone Bipolaridade" /> Bipolaridade</ToggleButton>
                        <ToggleButton value="26"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Espiritualidade.svg" height="30px" alt="Icone Espiritualidade" /> Espiritualidade</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                </>
                    ) : (
                        <>
                            <FiltrarProfissional filtrarProficional={settipoprofissional} />
                            <FiltroAssuntoMobile setassunto={setValueAssunto}  />
                            
                            
                        </>
                    )}
                    


                        
                    

                    <div className="CardInputFiltroPorNomeAtendente">

                        <TextField
                        id="outlined-search" label="Buscar por nome" type="search"
                        onChange={handleChangeSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-outline"/>
                                </InputAdornment>
                            ),
                        }}
                        />

                    </div>

                    <div className="CardBotaoFiltroAtendente">
                        <Button type="button" fullWidth color="primary" variant="contained" sx={{p: 1.5, mb: 0.5}} onClick={Buscar}>Buscar</Button>
                    </div>

                    <div className="CardBotaoAvancado">
                        <Button type="button" fullWidth color="primary" variant="outlined" sx={{p: 1.5, mt: 0.5}} onClick={handleOpenDialogFiltroAvancado}> <Iconify icon="eva:options-2-fill"/> Filtros Avançados</Button>
                    </div>

                </div>
                ) : (
                    <>
                        <div className="CardBotaoFiltroAtendente">
                            <Button type="button" sx={{p: 1.5, mb: 0.5}} fullWidth color="primary" variant="contained" onClick={NovaBusca}> <Iconify sx={{mr: 0.5}} icon='eva:plus-fill' /> Nova Busca</Button>
                        </div>

                        <Typography className="TituloCardFiltroAtendente">Resultado da sua busca.</Typography>
                    </>
                )}


                <FiltroAvancadoDesktop 
                        openFiltroAvancado={dialogOpenFiltroAvancado}
                        handleCloseFiltroAvancado={handleCloseDialogFiltroAvancado}
                        setassunto={setValueAssunto} 
                        setquery={setquery} 
                        settipoprofissional={settipoprofissional} 
                        setpreco={setpreco}
                        setavaliacao={setavaliacao}
                        setpage={setpage}
                    />



            </div>
        </>
    );
}
