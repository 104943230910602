// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require */

module.exports = function initSessionFactory(deps = {}) {
  const Session = deps.Session || require('./index.js')();
  const sessionObjects = deps.sessionObjects || require('./objects.js');

  /**
  * The first step in using the OpenTok API is to call the <code>OT.initSession()</code>
  * method. Other methods of the OT object check for system requirements and set up error logging.
  *
  * @class OT
  */

  /**
  * <p class="mSummary">
  * Initializes and returns the local session object for a specified session ID.
  * </p>
  * <p>
  * You connect to an OpenTok session using the <code>connect()</code> method
  * of the Session object returned by the <code>OT.initSession()</code> method.
  * Note that calling <code>OT.initSession()</code> does not initiate communications
  * with the cloud. It simply initializes the Session object that you can use to
  * connect (and to perform other operations once connected).
  * </p>
  *
  * <p>
  * Note that you can initialize only one Session object per session ID. Calling
  * <code>OT.initSession()</code> a second time with the same session ID returns the
  * previously created Session object. However, you can initialize multiple Session objects
  * for different sessions (with different session IDs) to connect to multiple sessions.
  * </p>
  *
  * <p>
  * When the client disconnects from the session (and the session dispatches a
  * <code>sessionDisconnected</code> event), you can reuse the Session object to
  * reconnect to the session. However, the Session object will retain any existing
  * event handlers attached to it. You can remove all event handlers by calling the
  * <code>off()</code> method of the Session object (passing in no parameters).
  * </p>
  *
  *  <p>
  *    For an example, see <a href="Session.html#connect">Session.connect()</a>.
  *  </p>
  *
  * @method OT.initSession
  * @memberof OT
  * @param {String} apiKey Your OpenTok API key (see your <a href="https://tokbox.com/account">Vonage Video API
  * account page</a>).
  * @param {String} sessionId The session ID identifying the OpenTok session. For more
  * information, see <a href="https://tokbox.com/opentok/tutorials/create-session/">Session
  * creation</a>.
  * @param {Object} options An object that includes optional options for initializing the session.
  * This object includes the following properties:
  * <ul>
  *   <li><code>connectionEventsSuppressed</code> &mdash; (Boolean)
  *     Set this property to <code>true</code> to support large interactive video sessions.
  *     This prevents the Session object from dispatching <code>connectionCreated</code> and
  *     <code>connectionDestroyed</code> events when other clients connect to or disconnect from
  *     the session. (Also, the OpenTok server does not send these events to the web client.)
  *     For more information, see
  *     <a href="https://tokbox.com/developer/guides/broadcast/live-interactive-video/#suppressing-connection-events">
  *     Suppressing connection events</a> in the OpenTok developer guides.
  *   </li>
  *   <li><code>ipWhitelist</code> &mdash; (Boolean) This is available as an
  *     <a href="https://tokbox.com/pricing/plans" target="_blank">add-on feature</a>.
  *     Set this to <code>true</code> if IP white listing is enabled for your project.
  *     The default value is <code>false</code>.
  *   </li>
  *   <li><code>iceConfig</code> &mdash; (Object) This feature is part of the configurable TURN
  *     <a href="https://tokbox.com/pricing/plans" target="_blank">add-on feature</a>.
  *   </li>
  *   <li><code>encryptionSecret</code. (String) This is the initial encryption secret used by the
  *   end-to-end encryption feature. See <a href="https://www.tokbox.com/developer/guides/end-to-end-encryption/">
  *   the developer guide</a>.
  *   </li>
  * </ul>
  *
  * @returns {Session} The session object through which all further interactions with
  * the session will occur.
  */
  return function initSession(apiKey, sessionId, opt) {
    if (sessionId == null) {
      sessionId = apiKey;
      apiKey = null;
    }

    // Allow buggy legacy behavior to succeed, where the client can connect if sessionId
    // is an array containing one element (the session ID), but fix it so that sessionId
    // is stored as a string (not an array):
    if (Array.isArray(sessionId) && sessionId.length === 1) {
      sessionId = sessionId[0];
    }

    let session = sessionObjects.sessions.get(sessionId);

    if (!session) {
      session = new Session(apiKey, sessionId, opt);
      sessionObjects.sessions.add(session);
    }

    return session;
  };
};
