import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { CustomAvatar } from 'src/components/custom-avatar';
import { getCoresTemplate } from 'src/utils/coresTemplate';
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  Typography,
  Button,
  Grid,
  Divider,
  List,
  Chip,
  Dialog,
  AppBar,
  Toolbar,
  Paper,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogProps
} from '@mui/material';
import ToggleButton from 'src/components/settings/drawer/ToggleButton';

import confirmaDescontoNoPacote from '../img/confirmaDescontoNoPacote.png';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import BadgeStatus, { BadgeStatusValue } from '../../../../components/badge-status';

// utils
import axios from '../../../../utils/axios';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../../@types/chat';
// components
import Iconify from '../../../../components/iconify';
import { useAuthContext } from '../../../../auth/useAuthContext';
import Scrollbar from '../../../../components/scrollbar';

import '../Conversar.css';
import ConfirmarDescontoPacote from './ConfirmarDescontoPacote';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type Props = {
  Agendar: any;
  FecharAgendamento: any;
  Conversar: any;
  OutroAtendente: any;
  Voltar: any;
  usera: any;
  status: any;
};

export default function AtendenteOnOff({
  Agendar,
  OutroAtendente,
  Voltar,
  Conversar,
  FecharAgendamento,
  usera,
  status,
}: Props) {
  const theme = useTheme();

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('sm');

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const [open, setOpen] = useState(true);
  const [statusatendente, setstatusatedente] = useState('3px solid #CCC');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { user } = useAuthContext();

  console.log(status);

  
  const [openDescontoPacote, setOpenDescontoPacote] = useState(false);

    const handleClickOpenDescontoPacote = () => {
        setOpenDescontoPacote(true);
    };

    const handleCloseDescontoPacote = () => {
        setOpenDescontoPacote(false);
    };


  const [existePacote, setExistePacote] = useState(true);


  useEffect(() => {
    if (status === 'Disponivel') {
      setstatusatedente('3px solid #36B37E');
    } else if (status === 'Offline') {
      setstatusatedente('3px solid #FF5630');
    }
  }, [status]);

  return (
    <>
    
      {isDesktop ? (
        
        
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100vh' }}
        >
          <Grid container direction="column" justifyContent="space-between" alignItems="center">
            <div className="CardGeralConversar">
              {openDescontoPacote ? (
                <>
                <Grid
                container
                direction="column"
                justifyContent="flex-end"
                alignItems="center"
                >
                  <img src={confirmaDescontoNoPacote} width={220} style={{marginTop:'-140px'}} alt='Confirmação de Desconto do Pacote' />
                  <Typography variant='h4' color='primary'>Você possui N conversas através do pacote!</Typography>
                  <Typography variant='subtitle1'>Gostaria de confirmar que essa conversa será através do pacote?</Typography>
                </Grid>

                <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{mt: 3}}
                >
                  <Button variant='outlined' sx={{p: 1.5}} onClick={handleCloseDescontoPacote}>Voltar</Button>
                  <Button variant='contained' sx={{ml: 1, p: 1.5}}>Confirmar</Button>
                </Grid>

                </>
              ) : (
                <>
                <Grid container justifyContent="space-between">
                <IconButton onClick={Voltar}>
                  <Iconify icon="eva:arrow-ios-back-outline" />
                  <Typography variant="subtitle2">Voltar</Typography>
                </IconButton>

                <IconButton onClick={FecharAgendamento}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              </Grid>

              <Grid item container justifyContent="center" direction="column" alignItems="center">
                <Item>
                  <Chip
                    className={getCoresTemplate(usera?.TipoAtendente).chipListaAtendentes}
                    label={usera?.tipoatendente}
                    sx={{ mb: 2 }}
                    size="small"
                  />

                  <CustomAvatar
                    className="FotoPerfilAtendente"
                    src={usera?.avatar}
                    alt={usera?.nome}
                    name={usera?.nome}
                    sx={{
                      cursor: 'pointer',
                      width: 100,
                      height: 100,
                      border: statusatendente,
                    }}
                  />
                </Item>

                <Item>
                  {status === 'Disponivel' ? (
                    <Typography variant="h5" mt={1.5}>
                      {usera?.nome} <br /> está online e pode te atender agora.
                    </Typography>
                  ) : (
                    <Typography variant="h5" mt={1.5}>
                      {usera?.nome}
                      <br /> está offline no momento.
                    </Typography>
                  )}

                  {user?.jaconversou === 'n' ? (
                    <Typography my={3}>
                      Atendimento gratuito não é válido para agendamentos.
                    </Typography>
                  ) : (
                    ''
                  )}
                </Item>

                <Item>
                    {existePacote ? (
                      <Button
                      fullWidth
                      color='success'
                      variant="contained"
                      onClick={handleClickOpenDescontoPacote}
                      sx={{p: 1.5, mb: 1}}
                      >
                        <Iconify icon='eva:star-outline' sx={{mr: 1}} />
                        Utilizar conversa do pacote 
                      </Button>
                    ) : ('')}

                  {user?.jaconversou === 'n' ? (
                    <>
                      {' '}
                      {usera?.TipoAtendente > 1 ? (
                        <Button
                          fullWidth
                          className="btnAgendamento"
                          sx={{ p: 1.5, display: status === 'Disponivel' ? 'flex' : 'none' }}
                          variant="contained"
                          onClick={Conversar}
                          disabled={status !== 'Disponivel'}
                        >
                          Conversar agora
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          className="btnAgendamento"
                          sx={{ p: 1.5, display: status === 'Disponivel' ? 'flex' : 'none' }}
                          variant="contained"
                          color="success"
                          disabled={status !== 'Disponivel'}
                          onClick={Conversar}
                        >
                          Conversar Agora 100% Grátis
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      fullWidth
                      className="btnAgendamento"
                      sx={{ p: 1.5, display: status === 'Disponivel' ? 'flex' : 'none' }}
                      variant="contained"
                      onClick={Conversar}
                      disabled={status !== 'Disponivel'}
                    >
                      Conversar agora
                    </Button>
                  )}

                  <Button
                    fullWidth
                    className="btnAgendamento"
                    id="btn_agendamento"
                    sx={{ p: 1.5 }}
                    variant="contained"
                    onClick={Agendar}
                  >
                    Agendar atendimento
                  </Button>
                  <Button
                    fullWidth
                    className="btnAgendamento"
                    sx={{ p: 1.5 }}
                    variant="outlined"
                    onClick={OutroAtendente}
                  >
                    Escolher outro atendente
                  </Button>
                </Item>
              </Grid></>
              )}
              
            </div>
          </Grid>
        </Grid>
      ) : (
        
        <>

    {openDescontoPacote ? (
                <div className='dialogGeralMobileCardConfirmacaoDescontoPacote'>
                <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                >
                  <img src={confirmaDescontoNoPacote} width={220} alt='Confirmação de Desconto do Pacote' />
                  <Typography variant='h4' color='primary'>Você possui N conversas através do pacote!</Typography>
                  <Typography variant='subtitle1'>Gostaria de confirmar que essa conversa será através do pacote?</Typography>
                </Grid>

                <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{mt: 3}}
                >
                  <Button variant='outlined' sx={{p: 1.5}} onClick={handleCloseDescontoPacote}>Voltar</Button>
                  <Button variant='contained' sx={{ml: 1, p: 1.5}}>Confirmar</Button>
                </Grid>

                </div>
              ) : (
                <div className='dialogGeralMobileAtendenteOnOff'>
          
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="geralAgendamentoAtendenteOnOff"
            sx={{ px: 0, py: 0 }}
          >

            <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: 1.5, px: 1.5 }}
            >
              <IconButton onClick={Voltar}>
                <Iconify icon="eva:arrow-ios-back-outline" />
              </IconButton>

              <IconButton onClick={FecharAgendamento}>
                <Iconify icon="eva:close-fill" />
              </IconButton>
            </Grid>


            <Grid item xs={12}>
              <Item sx={{background:'transparent'}}>
                <Chip
                  label={usera?.tipoatendente}
                  className={getCoresTemplate(usera?.TipoAtendente).chipListaAtendentes}
                  size="small"
                />
              </Item>

              <Item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background:'transparent'
                }}
              >
                <CustomAvatar
                  className="FotoPerfilAtendente"
                  src={usera?.avatar}
                  alt={usera?.nome}
                  name={usera?.nome}
                  sx={{
                    cursor: 'pointer',
                    width: 100,
                    height: 100,
                    border:
                      usera?.StatusAtendimento === 'Disponivel'
                        ? '4px solid #36B37E'
                        : '4px solid #FF5630',
                  }}
                />
                {status === 'Disponivel' ? (
                  <Typography variant="h4" mt={1.5} gutterBottom>
                    {usera?.nome} <br /> está <span style={{ color: '#36B37E' }}>online</span> e
                    pode te atender agora.
                  </Typography>
                ) : (
                  <Typography variant="h4" mt={1.5} gutterBottom>
                    {usera?.nome}
                    <br /> está <span style={{ color: '#FF5630' }}>offline</span> e não pode te
                    atender agora.
                  </Typography>
                )}

                {user?.jaconversou === 'n' ? (
                  <Typography my={3}>
                    Atendimento gratuito não é válido para agendamentos.
                  </Typography>
                ) : (
                  ''
                )}
              </Item>
            </Grid>

            <Grid item xs={12} className="buttonsConversar">
              <Item>
              {existePacote ? (
                      <Button
                      fullWidth
                      color='success'
                      variant="contained"
                      onClick={handleClickOpenDescontoPacote}
                      sx={{p: 1.5, mb: 1}}
                      >
                        <Iconify icon='eva:star-outline' sx={{mr: 1}} />
                        Utilizar conversa do pacote 
                      </Button>
                    ) : ('')}
                    
                {user?.jaconversou === 'n' ? (
                  <>
                    {' '}
                    {usera?.TipoAtendente > 1 ? (
                      <Button
                        fullWidth
                        className="btnAgendamento"
                        sx={{ p: 1.5, display: status === 'Disponivel' ? 'flex' : 'none' }}
                        variant="contained"
                        onClick={Conversar}
                        disabled={status !== 'Disponivel'}
                      >
                        Conversar agora
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        className="btnAgendamento"
                        sx={{ p: 1.5, display: status === 'Disponivel' ? 'flex' : 'none' }}
                        variant="contained"
                        color="success"
                        disabled={status !== 'Disponivel'}
                        onClick={Conversar}
                      >
                        Conversar Agora 100% Grátis
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    fullWidth
                    className="btnAgendamento"
                    sx={{ p: 1.5, display: status === 'Disponivel' ? 'flex' : 'none' }}
                    variant="contained"
                    onClick={Conversar}
                    disabled={status !== 'Disponivel'}
                  >
                    Conversar agora
                  </Button>
                )}

                <Button
                  fullWidth
                  className="btnAgendamento"
                  id="btn_agendamento"
                  sx={{ p: 1.5 }}
                  variant="contained"
                  onClick={Agendar}
                >
                  Agendar atendimento
                </Button>
                <Button
                  fullWidth
                  className="btnAgendamento"
                  sx={{ p: 1.5 }}
                  variant="outlined"
                  onClick={OutroAtendente}
                >
                  Escolher outro atendente
                </Button>
              </Item>
            </Grid>
          </Grid>
          </div>
              )}

        

          
        </>
      )}
    </>
  );
}
