import { useState} from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Box,
  Chip,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  SelectProps,
  FormControl,
  OutlinedInput,
  TextFieldProps,
  FormHelperText,
} from '@mui/material';
import ReactFlagsSelect from "react-flags-select";
import '../../sections/auth/Cadastro/Cadastro.css';
import './hookform.css';

// ----------------------------------------------------------------------

type RHFSelectProps = TextFieldProps & {
  name: any;
};

export function RHFSelectBandeiras({
  name
}: RHFSelectProps) {
  const { control } = useFormContext();

  const [select, setSelect] = useState("BR");

  return (
    <Controller
      name={name}
      defaultValue="BR"
      control={control}
      render={({ field, fieldState: { error } }) => (
          <>
          <ReactFlagsSelect
              selected={field.value}
              onSelect={(code) => field.onChange(code)}
              placeholder="País"
              searchable
              className={`banner ${!error ? "Pais_Cadastro" : "Pais_Cadastro inputmasktelefoneerro2"}`}
          />
              {(!!error ) && (
                <FormHelperText error={!!error} sx={{ mx: 0 }} className='FormHelperTexttelefone'>
              {error ? error?.message :''}
                </FormHelperText>
                )}
          </>
      )}
    />
  );
}


