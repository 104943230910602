import {useState, useEffect, useCallback} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Typography,
    ButtonGroup,
    Button,
    Paper
} from '@mui/material';

import useResponsive from '../../../../hooks/useResponsive';

import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";

import {useAuthContext} from "../../../../auth/useAuthContext";

import '../Agendamento.css';
import axios from "../../../../utils/axios";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  type Props = {
    diaDaSemana: any;
    dia: any;
      horariosdados: any;
    selectedHorario: string | null;
    handleHorarioSelect: (value: string) => void;
  };

  export default function Horarios({
    diaDaSemana,
    dia,
    selectedHorario,
    handleHorarioSelect,
    horariosdados,
  }: Props) {

    const [localSelectedValue, setLocalSelectedValue] = useState<string | null>(null);

  const handleLocalHorarioSelect = (value: string) => {
    setLocalSelectedValue(value);
    handleHorarioSelect(value); // Chame a função do componente pai para atualizar o estado global
      console.log(value);
  };

    const [status, setStatus] = useState<BadgeStatusValue>('online');


    const theme = useTheme();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const [open, setOpen] = useState(true);

      const {idAtendente = ''} = useParams();
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


  
    const { user } = useAuthContext();



    return (
        <>
        { horariosdados.length !== 0 ? (        <>
                <Typography
                    sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: 'h4.fontSize', mt: 2, mb: 1 }}
                >
                    {diaDaSemana} - {dia}
                </Typography>
                <ButtonGroup variant="outlined" className='buttonGroupHorarios' aria-label="outlined button group">

                    {  horariosdados.map((horario:any,index:any) => (
                        <Button
                            key={`${dia} ${horario.horaDia}:00`}
                            value={`${dia} ${horario.horaDia}:00`}
                            variant={`${dia} ${horario.horaDia}:00` === (selectedHorario || localSelectedValue) ? 'contained' : 'outlined'}
                            onClick={() => handleLocalHorarioSelect(`${dia} ${horario.horaDia}:00`)}
                        >
                            {`${horario.horaDia}:00`}
                        </Button>
                    ))}

                </ButtonGroup>

            </>):('')}
        </>
    )
}
