// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  chat: icon('ic_chat'),
  dashboard: icon('ic_dashboard'),
  atendentes: icon('ic_atendentes'),
  /* agendamentos: icon('ic_calendar'), */
  financeiro: icon('ic_banking'),
  avaliacao: icon('ic_label'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Eyhe 5.0',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.dashfirst, icon: ICONS.dashboard },
      { title: 'Atendimentos', path: PATH_DASHBOARD.atendimentos, icon: ICONS.chat },
      /* { title: 'Agendamentos', path: PATH_DASHBOARD.agendamentos, icon: ICONS.agendamentos }, */
      { title: 'Atendentes', path: PATH_DASHBOARD.atendentes, icon: ICONS.atendentes },
      { title: 'Financeiro', path: PATH_DASHBOARD.financeiro, icon: ICONS.financeiro },
      { title: 'Avaliações', path: PATH_DASHBOARD.avaliacoes, icon: ICONS.avaliacao },
    ],
  },


];

export default navConfig;
