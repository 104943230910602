import {Divider,Tabs, Tab, IconButton,Button,TextField,Popover , List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper,Card, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Iconify from "src/components/iconify";
import { styled } from '@mui/material/styles';
import useResponsive from "src/hooks/useResponsive";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import {useSettingsContext} from '../../../../components/settings';
import {useAuthContext} from "../../../../auth/useAuthContext";
// @mui

import axios from "../../../../utils/axios";
import {PATH_AUTH, PATH_DASHBOARD} from "../../../../routes/paths";

import './AgendamentoRemarcado.css';

import confirmaAgendamento from './assets/confirmaAgendamento.svg'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
type Props = {
    agendamento: any;
    recarregar: any;
};


  export default function AgendamentoRemarcado({agendamento, recarregar}:Props){


      const navigate = useNavigate();
      const { enqueueSnackbar } = useSnackbar();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const abrirchat = () => {
        navigate(PATH_DASHBOARD.chat.view(agendamento.tokenConversa));
    };
    const abrirperfil= () => {
        navigate(PATH_DASHBOARD.verperfil(agendamento.IDAtendente));
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


   
    const [openInfoAgendamento, setOpenInfoAgendamento] = useState(false);

    const handleClickOpenInfoAgendamento = () => {
        setOpenInfoAgendamento(true);
    };

    const handleCloseInfoAgendamento = () => {
        setOpenInfoAgendamento(false);
    };



      const [isloadingRecusar, setisloadingRecusar] = useState(false);
      const [isloadingAceitar, setisloadingAceitar] = useState(false);



    const Recusar = async () => {
        setisloadingRecusar(true);

        try {
            const response = await axios.post('/areadoheroi/agendamento/recusar', {idAgendamento: agendamento.id});


            enqueueSnackbar(`Recusada com sucesso`, {variant: 'success'});
            setisloadingRecusar(false);
            handleCloseInfoAgendamento();
            recarregar();

        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            setisloadingRecusar(false);
        }

    };

    const Aceitar = async () => {
        setisloadingAceitar(true);


        try {
            const response = await axios.post('/areadoheroi/agendamento/aceitar', {idAgendamento: agendamento.id});


            enqueueSnackbar(`Aceito com sucesso`, {variant: 'success'});
            setisloadingAceitar(false);
            handleCloseInfoAgendamento();
            recarregar();

        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            setisloadingAceitar(false);
        }


    };





    return(
        <div className="MeusAgendamentosDashInicialRemarcacao">
                    <div>
                        
                        <Typography variant="h5" sx={{color: '#7a4100'}}>Atendente remarcou agendamento</Typography>
                        <Typography variant="subtitle2" sx={{color: '#7a4100', mb: 1}}>O atendente remarcou seu agendamento para outro momento, verifique se você terá disponibilidade.</Typography>

                       

                        <div className="AgendamentoRemarcacaoDados">
                            
                            <div className="AgendamentoRecorrenteFotoAnjo">
                                <CustomAvatar
                                    className="FotoPerfilAtendente"
                                    src={agendamento?.avatar}
                                    alt={agendamento?.nome}
                                    name={agendamento?.nome}
                                    sx={{ cursor: 'pointer', width: 50, height: 50 }}
                                />
                                <div className="AgendamentoRecorrenteNomeeFuncaoAnjo">
                                        <Typography className="NomeAnjoAgendamentoRecorrente">{agendamento?.nome}</Typography>
                                        <div style={{display: 'inline-flex'}}>
                                            <div className="DataAgendamentoRecorrente" style={{marginRight: '5px'}}>
                                                <Iconify icon="eva:calendar-outline"/>
                                                <Typography>{agendamento?.data}</Typography>
                                            </div>
                                            <div className="HoraAgendamentoRecorrente" style={{marginLeft: '5px'}}>
                                                <Iconify icon="eva:clock-outline"/>
                                                <Typography>{agendamento?.hora}</Typography>
                                            </div>
                                        </div>
                                </div>
                            </div>

                        <Dialog
                            open={openInfoAgendamento}
                            onClose={handleCloseInfoAgendamento}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle>
                                <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                >
                                    <IconButton onClick={handleCloseInfoAgendamento}>
                                        <Iconify icon='eva:close-fill' />
                                    </IconButton>
                                </Grid>
                            </DialogTitle>
                            <DialogContent sx={{pt: 3, display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
                                
                                <img src={confirmaAgendamento} width='300px' style={{margin: '10px 0px'}} alt="Imagem Confirmação de Reagendamento"/>

                                <Typography variant="h5" sx={{textAlign: 'center'}}>Tem certeza que deseja aceitar a remarcação sugerida pelo atendente? Se sim, clique em <b>Aceitar</b> abaixo.</Typography>
                            
                            </DialogContent>
                            
                            <DialogActions sx={{display: 'flex', width: '100%'}}>
                            <LoadingButton

                                variant="contained"
                                loading={isloadingRecusar}
                                onClick={Recusar}
                                color="warning"
                                sx={{py: 1, px: 2}}
                                >
                                    Recusar
                                </LoadingButton>

                                <LoadingButton
                                 loading={isloadingAceitar}
                                variant="contained"
                                 onClick={Aceitar}
                                color="primary"
                                sx={{py: 1, px: 2}}
                                >
                                    Aceitar
                                </LoadingButton>

                                
                            </DialogActions>
                            
                        </Dialog>

                        </div>

                    </div>

                    <div className="BotaoMaisOpcoes" style={{marginTop: '10px'}}>
                            <Button sx={{backgroundColor: '#FFAB00' , color: '#fff' , mr: 0.5, p: 1}} className="buttonsBotaoMaisOpcoesAgendamentoRemarcadoAceitar" onClick={handleClickOpenInfoAgendamento} fullWidth>Aceitar</Button>
                            <Button sx={{backgroundColor: '#ffd666' , color: '#7a4100' , ml: 0.5, p: 1}} className="buttonsBotaoMaisOpcoesAgendamentoRemarcadoRecusar" onClick={handleClickOpenInfoAgendamento} fullWidth>Recusar</Button>
                    </div>
                </div>


    

    )

  }
  