import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { CustomAvatar } from 'src/components/custom-avatar';
import {
  IconButton,
  Typography,
  Button,
  Grid,
  Divider,
  List,
  Dialog,
  AppBar,
  Toolbar,
  Rating,
  Chip,
  Box,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  ButtonGroup,
} from '@mui/material';

import dayjs from 'dayjs';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import BadgeStatus, { BadgeStatusValue } from '../../../../components/badge-status';

// utils
import axios from '../../../../utils/axios';
import '../Agendamento.css';
import Horarios from './horarios';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../../@types/chat';
// components
import Iconify from '../../../../components/iconify';
import { useAuthContext } from '../../../../auth/useAuthContext';
import Scrollbar from '../../../../components/scrollbar';

import WeeklyCalendar from './calendario';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type Props = {
  ConfirmarAgendamento: any;
  FecharAgendamento: any;
  Voltar: any;
  usera: any;
  setSelectedHorario: any;
};

export default function MaisOpcoesHorariosAgendamento({
  ConfirmarAgendamento,
  FecharAgendamento,
  Voltar,
  usera,
  setSelectedHorario,
}: Props) {
  const [alignment, setAlignment] = useState<string | null>('');

  const [localSelectedValue, setLocalSelectedValue] = useState<string | null>(null);
  const [selectedHorario, handleHorarioSelect] = useState<string | null>(null);
  const [diaSelect, setdiaSelect] = useState<string | null>(null);
  const [horariosdados, sethorariosdados] = useState<any>([]);
  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    console.log(newAlignment);
    setAlignment(newAlignment);
  };

  const handleLocalHorarioSelect = (value: string) => {
    setLocalSelectedValue(value);
    setSelectedHorario(value);
    handleHorarioSelect(value); // Chame a função do componente pai para atualizar o estado global
    console.log(value);
  };

  const [status, setStatus] = useState<BadgeStatusValue>('Disponivel');

  const theme = useTheme();

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const [open, setOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { user } = useAuthContext();

  const [dia, mudardia] = useState<any>(dayjs().format('YYYY-MM-DD'));

  const getmessage = useCallback(async () => {
    try {
      const response = await axios.post(`/areadoheroi/horarioscompleto`, {
        idAtendente: usera?.id,
        dia,
      });

      const { horarios } = response.data;

      sethorariosdados(horarios);
    } catch (error) {
      console.error(error);
    }
  }, [usera, dia]);

  useEffect(() => {
    getmessage();
  }, [getmessage]);

  return (
    <>
      

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="geralAgendamentoHorarios"
        sx={{ px: 0, py: 0 }}
        maxWidth="md"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="TopoAgendamentoProfile"
          maxWidth="lg"
          >
            <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 1.5, px: 0 }}
        >
        <IconButton onClick={Voltar}>
          <Iconify icon="eva:arrow-ios-back-outline" />
          <Typography variant="subtitle2">Voltar</Typography>
        </IconButton>

        <IconButton onClick={FecharAgendamento}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Grid>
          <div>
            <div className="FotoeNomeAtendenteAgendamento">
              <CustomAvatar
                className="FotoPerfilAtendente"
                src={usera?.avatar}
                alt={usera?.nome}
                name={usera?.nome}
                sx={{
                  cursor: 'pointer',
                  width: 60,
                  height: 60,
                  border:
                    usera?.StatusAtendimento === 'Disponivel'
                      ? '3px solid #36B37E'
                      : '3px solid #FF5630',
                }}
              />
              <div>
                <Typography sx={{ ml: 2, fontWeight: 'bold' }}>{usera.nome}</Typography>
                <Rating sx={{ ml: 2 }} name="read-only" value={usera.estrelas} readOnly />
              </div>
            </div>
          </div>
          <div>
            <Chip label={usera?.tipoatendente} color="primary" />
          </div>
        </Grid>

        <Grid container direction="row" alignItems="center" maxWidth="lg" sx={{ px: 2.5 }}>
          <Grid item xs={12}>
            <Item>
              <Typography sx={{ textTransform: 'uppercase', textAlign: 'left' }}>
                Selecione o mês e o dia para visualizar os horários disponíveis
              </Typography>
            </Item>

            <Item className="tabsHorarios">
              <WeeklyCalendar mudardia={mudardia} />
              {horariosdados.length !== 0 ? (
                <ButtonGroup
                  variant="outlined"
                  className="buttonGroupHorarios"
                  aria-label="outlined button group"
                >
                  {horariosdados.map((horario: any, index: any) => (
                    <Button
                      key={`${dia} ${horario.horaDia}:00`}
                      value={`${dia} ${horario.horaDia}:00`}
                      variant={
                        `${dia} ${horario.horaDia}:00` === (selectedHorario || localSelectedValue)
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() => handleLocalHorarioSelect(`${dia} ${horario.horaDia}:00`)}
                    >
                      {`${horario.horaDia}:00`}
                    </Button>
                  ))}
                </ButtonGroup>
              ) : (
                'Nenhum horário disponível, escolha outra data.'
              )}
            </Item>

            <Item>
              <Button
                fullWidth
                className="btnAgendamento"
                sx={{ p: 1.5 }}
                variant="contained"
                onClick={ConfirmarAgendamento}
              >
                Continuar
              </Button>
              <Button
                fullWidth
                className="btnAgendamento"
                sx={{ p: 1.5 }}
                variant="outlined"
                onClick={Voltar}
              >
                Voltar
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
