// @mui
import { styled } from '@mui/material/styles';
import { Link, Card, Typography, CardHeader, Stack, Button } from '@mui/material';
// @types
import { IUserProfileAbout } from '../../../../@types/user';
// components
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------
interface Props {
  Atendente: any;
}
export default function ProfileBiografia({
                                       Atendente,

}: Props) {



  return (
    <Card className='PerfilDoAtendenteCard'>

      <Stack spacing={2} sx={{ p: 3 }}>
        
        <Stack direction="row">
          <StyledIcon icon="eva:message-square-outline" />
          <Typography variant="body2">{Atendente.Biografia}</Typography>
        </Stack>

      </Stack>
    </Card>
  );
}
