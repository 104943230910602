import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

type Props = TextFieldProps & {
  name: string;
  defaultValue?: string; // Adicione defaultValue como uma prop
};

export default function RHFTextField({ name, helperText, defaultValue, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        // Use o valor padrão (defaultValue) se o valor do campo for undefined ou null
        const fieldValue = field.value !== undefined && field.value !== null ? field.value : defaultValue || '';

        return (
          <TextField
            {...field}
            fullWidth
            value={fieldValue}
            error={!!error}
            helperText={error ? error.message : helperText}
            {...other}
          />
        );
      }}
    />
  );
}
