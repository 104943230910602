import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { CustomAvatar } from 'src/components/custom-avatar';
import {
  IconButton,
  Typography,
  Button,
  Grid,
  Card,
  Divider,
  List,
  Dialog,
  AppBar,
  Toolbar,
  Rating,
  Chip,
  Box,
  Tab,
  Tabs,
  Paper,
} from '@mui/material';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import BadgeStatus, { BadgeStatusValue } from '../../../../components/badge-status';

// utils
import axios from '../../../../utils/axios';
import '../Agendamento.css';
import Horarios from './horarios';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../../@types/chat';
// components
import Iconify from '../../../../components/iconify';
import { useAuthContext } from '../../../../auth/useAuthContext';
import Scrollbar from '../../../../components/scrollbar';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type Props = {
  AlterarAgendamento: any;
  FecharAgendamento: any;
  Voltar: any;
  selectedHorario: any;
  usera: any;
};

export default function ConfirmarAgendamento({
  AlterarAgendamento,
  FecharAgendamento,
  Voltar,
  selectedHorario,
  usera,
}: Props) {
  const [status, setStatus] = useState<BadgeStatusValue>('Disponivel');

  const theme = useTheme();

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const [open, setOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmarAgendamento = async () => {
    const dados = { selectedHorario, id: usera.id };
    sessionStorage.setItem('dadosAgendamento', JSON.stringify(dados));
    navigate(PATH_DASHBOARD.pagamento(usera.id));
  };

  const { user } = useAuthContext();

  const [value, setValue] = useState<number | null>(4);

  function FormataStringData(data: any) {
    if (data.includes('/')) {
      return data;
    }

    const ano = data.split('-')[0];
    const mes = data.split('-')[1];
    const dia = data.split('-')[2];
    const diacerto = dia.split(' ')[0];
    const hora = data.split(' ')[1];

    return `${`0${diacerto}`.slice(-2)}/${`0${mes}`.slice(-2)}/${ano}  ${`0${hora}`.slice(-5)}`;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  function retornahora(data: any) {
    const hora = data.split(' ')[1];

    return ` ${`0${hora}`.slice(-5)}`;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  function diadasemana(data: any) {
    let diaSemana: any = 0;

    if (data.includes('-')) {
      diaSemana = data;
    } else {
      const ano2 = data.split('/')[2];
      const ano = ano2.split(' ')[0];
      const mes = data.split('/')[1];
      const dia = data.split('/')[0];
      const diacerto = dia.split(' ')[0];

      diaSemana = `${`0${ano}`}-${`0${mes}`.slice(-2)}-${diacerto}`;
    }

    const semana = [
      'Domingo',
      'Segunda-Feira',
      'Terça-Feira',
      'Quarta-Feira',
      'Quinta-Feira',
      'Sexta-Feira',
      'Sábado',
    ];
    const diaSemanacerto = new Date(diaSemana).getDay();

    return semana[diaSemanacerto];
  }

  return (
    <>
      

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="geralAgendamentoHorarios"
        sx={{ px: 0, py: 0 }}
        maxWidth="md"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="TopoAgendamentoProfile"
          maxWidth="lg"
        >
          <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 1.5, px: 0 }}
        >
        <IconButton onClick={Voltar}>
          <Iconify icon="eva:arrow-ios-back-outline" />
          <Typography variant="subtitle2">Voltar</Typography>
        </IconButton>

        <IconButton onClick={FecharAgendamento}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Grid>

          <Typography sx={{ width: '100%', mb: 4, textAlign: 'center' }} variant="h3" gutterBottom>
            Confirmação de agendamento
          </Typography>

          <div>
            <div className="FotoeNomeAtendenteAgendamento">
              <CustomAvatar
                className="FotoPerfilAtendente"
                src={usera?.avatar}
                alt={usera?.nome}
                name={usera?.nome}
                sx={{ cursor: 'pointer', width: 60, height: 60 }}
                BadgeProps={{
                  badgeContent: <BadgeStatus status={usera.StatusAtendimento} />,
                }}
              />
              <div>
                <Typography sx={{ ml: 2, fontWeight: 'bold' }}>{usera.nome}</Typography>
                <Rating sx={{ ml: 2 }} name="read-only" value={usera.estrelas} readOnly />
              </div>
            </div>
          </div>
          <div>
            <Chip label={usera?.tipoatendente} color="primary" />
          </div>
        </Grid>

        <Grid container maxWidth="lg">
          <Grid item xs={12} sx={{ p: 2.5 }}>
            <Item className="tabsHorariosConfirmacao">
              <Card className="CardConfirmacaoHorario">
                <Typography>
                  {FormataStringData(selectedHorario)} ({diadasemana(selectedHorario)})
                </Typography>
                <Chip
                  className="chipConfirmacaoHorario"
                  label={retornahora(selectedHorario)}
                  variant="outlined"
                />
              </Card>
            </Item>

            <Item>
              <Button
                fullWidth
                className="btnAgendamento"
                sx={{ p: 1.5 }}
                variant="contained"
                onClick={confirmarAgendamento}
              >
                Confirmar agendamento
              </Button>
              <Button
                fullWidth
                className="btnAgendamento"
                sx={{ p: 1.5 }}
                variant="outlined"
                onClick={AlterarAgendamento}
              >
                Alterar data e hora
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
