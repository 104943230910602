import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import Atendimento from "../sections/@dashboard/Atendimento/Atendimento";
import {ConfirmarCadastro} from "../sections/@dashboard/confimarcadastro";



export default function ConfimarCadastroPage() {
  return (
    <>
      <Helmet>
        <title>Confirmar Cadastro do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <ConfirmarCadastro  />
    </>
  );
}
