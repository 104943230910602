import {
  Dialog,
  DialogTitle,
  Checkbox,
  FormGroup,
  FormControlLabel,
  DialogProps,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  DialogContent,
  DialogActions,
  ListItemText,
  Box,
  Rating,
  useMediaQuery,
  ListItem,
  List,
  Tooltip,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Slider,
  Slide,
  Button,
  TextField,
  Tabs,
  Tab,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { CustomAvatar } from 'src/components/custom-avatar';
import { useSettingsContext } from '../../../../components/settings';
import { useAuthContext } from '../../../../auth/useAuthContext';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import './FiltrarAtendente.css';
import BadgeStatus, { BadgeStatusValue } from '../../../../components/badge-status';
import axios from '../../../../utils/axios';

import iconAtendente1 from '../img/love.svg';
import iconAtendente2 from '../img/psychology.svg';
import iconAtendente4 from '../img/terapia.png';
import iconAtendente5 from '../img/coach.png';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import FiltroTipoAtendenteMobile from './FiltroTipoAtendenteMobile';
import FiltroAssuntoMobile from './FiltroAssuntoMobile';

const icon = <Iconify icon="eva:square-outline" />;
const checkedIcon = <Iconify icon="eva:checkmark-square-2-fill" />;

const labels: { [index: string]: string } = {
  0.5: '',
  1: '',
  1.5: '',
  2: '',
  2.5: 'Ok',
  3: 'Bom',
  3.5: 'Muito bom',
  4: 'Excelente',
  4.5: 'Espetacular',
  5: 'Atendimento Perfeito',
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function valuetext(value: number) {
  return `${value}°C`;
}

const minDistance = 18;

type props = {
  openFiltroAvancado: any;
  handleCloseFiltroAvancado: any;
  setassunto: any;
  setquery: any;
  settipoprofissional: any;
  setavaliacao: any;
  setpreco: any;
  setpage: any;
};

export default function FiltroAvancadoDesktop({
  setpage,
  setassunto,
  setquery,
  settipoprofissional,
  setavaliacao,
  setpreco,
  openFiltroAvancado,
  handleCloseFiltroAvancado,
}: props) {
  const theme = useTheme();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');

  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const [busca, setBusca] = useState(true);
  const [valuequery, setvaluequery] = useState('');

  const NovaBusca = () => {
    setBusca(true);
  };

  const [state, setState] = React.useState({
    um: false,
    dois: false,
    tres: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [valueProfissional, setValueProfissional] = React.useState('');
  const handleProficional = (event: React.SyntheticEvent, newValue: any) => {
    setValueProfissional(newValue);
    settipoprofissional(newValue);
  };

  const [valueAssunto, setValueAssunto] = React.useState('');
  const handleAssunto = (event: React.SyntheticEvent, newValue: any) => {
    setValueAssunto(newValue);
    setassunto(newValue);
  };

  const [value, setValue] = React.useState<number | null>(0);
  const [value1, setValue1] = React.useState<number[]>([20, 80]);

  const Buscar = () => {
    setBusca(false);
    setassunto(valueAssunto);
    setquery(valuequery);
    settipoprofissional(valueProfissional);
    setpreco(state);
    setavaliacao(value);
    handleCloseFiltroAvancado();
    setpage(1);
  };

  const handleChangePreco = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setvaluequery(event.target.value);
      setpage(1);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClean = () => {
    setValueProfissional('');
    setValueAssunto('');
    setValue1([20, 80]);
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openFiltroAvancado}
      onClose={handleCloseFiltroAvancado}
      aria-labelledby="responsive-dialog-title"
      scroll={scroll}
    >
      <DialogTitle id="responsive-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Filtros avançados
          </Typography>

          <Button
            sx={{ p: 0, m: 0, position: 'relative', left: '20px' }}
            autoFocus
            color="inherit"
            onClick={handleCloseFiltroAvancado}
          >
            <Iconify icon="eva:close-outline" />
          </Button>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <TextField
          id="outlined-search"
          label="Buscar por nome"
          type="search"
          onChange={handleChangeSearch}
          sx={{ px: 2, mt: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-outline" />
              </InputAdornment>
            ),
          }}
        />

        <div className="FiltrosProfissionaleAssunto">
          <FiltroTipoAtendenteMobile settipoprofissional={settipoprofissional} />
          <FiltroAssuntoMobile setassunto={setValueAssunto} />
        </div>

        <Divider />

        <div className="FaixaDePrecoFiltroAvancado">
          <Typography className="SubTituloCardFiltroAtendente">Filtro de Preço</Typography>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={state.um} onChange={handleChange} name="um" />}
              label="R$0 - R$39.90 "
            />
            <FormControlLabel
              control={<Checkbox checked={state.dois} onChange={handleChange} name="dois" />}
              label="R$40 - R$59.90"
            />
            <FormControlLabel
              control={<Checkbox checked={state.tres} onChange={handleChange} name="tres" />}
              label=" + R$60.00"
            />
          </FormGroup>
        </div>

        <Divider />
      </DialogContent>
      <DialogActions>
        <Button sx={{ p: 1.5, mr: 0.5 }} color="primary" variant="outlined" onClick={handleClean}>
          {' '}
          <Iconify sx={{ mr: 0.5 }} icon="eva:loader-outline" /> Limpar
        </Button>
        <Button sx={{ p: 1.5, ml: 0.5 }} color="primary" variant="contained" onClick={Buscar}>
          {' '}
          <Iconify sx={{ mr: 0.5 }} icon="eva:search-fill" /> Buscar Atendente
        </Button>
      </DialogActions>
    </Dialog>
  );
}
