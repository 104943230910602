// @mui
import { enUS, ptBR, esES  } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [

  {
    label: 'Português (BR)',
    value: 'pt-br',
    systemValue: ptBR,
    icon: '/assets/icons/flags/brazil.png',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Espanhol',
    value: 'es',
    systemValue: esES,
    icon: '/assets/icons/flags/espanha.png',
  },

];

export const defaultLang = allLangs[0]; // English
