// @mui
import {Card, Stack, Typography, Divider, Grid, Button, Paper, Rating, Pagination} from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
// utils
import {styled} from "@mui/material/styles";
import ptBR from "date-fns/locale/pt-BR/index";
import { fNumber } from '../../../../utils/formatNumber';
// @types
import { IUserProfileFollowers } from '../../../../@types/user';
import Iconify from "../../../../components/iconify";
import {CustomAvatar} from "../../../../components/custom-avatar";
import FotoPerfil from "../background_verperfil.jpg";

import '../VerPerfil.css';

// ----------------------------------------------------------------------
interface Props {
  avaliacoes: any;
  handleChange: any;

}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function Avaliacoesperfil({avaliacoes,handleChange}:Props) {

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  return (
      <Grid container className='GlobalCardsAvaliacoes'>
        {avaliacoes.data.map((avalia:any) => (
        <Grid item md={3} xs={12}>
          
          <Item>
            <div className="cardAvaliacoesVerPerfil">
              { /* <CustomAvatar
                  className="FotoPerfilAtendente"
                  src={avalia.avatar}
                  alt={avalia.nome}
                  name={avalia.nome}
                  sx={{ cursor: 'pointer', width: 80, height: 80 }}
        /> */ }
              <div className='NomeLocaleDepoimento'>
              { /* <h3>{avalia.nome}</h3> */ }
                <div className="LocalDepoimento">
                { /* <Iconify icon="eva:pin-outline" /> */ }
                { /*   <Typography>{avalia.created_at} </Typography>  */ }
                </div>
                
                  <Typography variant='subtitle2' sx={{textAlign: 'left'}}>
                    {formatDate(avalia.created_at)}
                  </Typography>
              

                <Rating name="read-only" value={avalia.estrelas} readOnly />

                <div className='TextoAvaliacaoVerPerfil'>
                  <Typography variant='subtitle1' sx={{textAlign: 'left'}}>{avalia.texto}</Typography>
                </div>
                

    
                
              </div>
            </div>
          </Item>
          
        </Grid>
))}

        <Stack className='paginationChatAtendimentoLista' spacing={2}>
          <Pagination count={avaliacoes.last_page} variant="outlined" onChange={handleChange} shape="rounded" />
        </Stack>

      </Grid>
  );
}
