import * as React from 'react';
import {styled} from '@mui/material/styles';

import {CssBaseline, Box, Container,Grid,Paper, IconButton, Typography, Tooltip, Button, Link } from "@mui/material";
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import "./notifica.css";
// @mui
import useResponsive from "../../../../hooks/useResponsive";

import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle
import notificacaoAudio from '../img/notificacao_audio.png'

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
// eslint-disable-next-line import/no-cycle




// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


type props = {
    fecharmodal: any,
    dados: any,
};
export default function NotificaAudio({fecharmodal,dados}:props) {

    const navigate = useNavigate();
    const iniciarchamada = ()=>{
        fecharmodal();
        navigate(PATH_DASHBOARD.chat.audio(dados.tokenConversa),{replace:true});
    }

    const isDesktop = useResponsive('up', 'lg');

    return (
        <>

        {isDesktop ? (
            <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{height: '100vh'}}
            >
                <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                >
                    <div className="CardPixDesktop">
                        <Grid container justifyContent="space-between">
                            
                        <IconButton onClick={fecharmodal}>
                                <Iconify icon="eva:arrow-ios-back-outline" />
                            </IconButton>
                            
                            
                        </Grid>

                        <Grid item container justifyContent="center">
                            <Item>
                                <img alt="Boleto Gerado" width="300px" src={notificacaoAudio}/>
                            </Item>
                    
                            <Item>
                                <Typography className="TextoTituloDialogNotificacaoPagamento">O Anjo {dados.anjo} solicitou uma chamada de áudio com você. Clique no botão abaixo para aceitar:</Typography>

                                <Button onClick={iniciarchamada} variant="contained" sx={{py: 1.5, mt: 1.5}}  fullWidth> <Iconify icon="eva:phone-fill" className='iconButtonNotification'/> Aceitar chamada</Button>

                                <Typography sx={{mt: 1.5}} > 
                            Como funciona?  
                                <Link href="#" color="#3366FF" underline="none">
                                    Clique aqui
                                </Link>     
                            </Typography>
                            </Item>
                        </Grid>


                    </div>
                    </Grid>
            </Grid>
        ) :
        
        
        (
            <><CssBaseline/>
            <Container maxWidth="xl" className='GlobalNotificaAudio'>
                
                <div className='BtnFecharNotificaAudio'>
                    <button type="button" onClick={fecharmodal} className='BotaoFecharNotificaAudio'>
                        <Iconify icon="eva:close-fill" className='iconButtonNotification'/> 
                    </button>
                    
                </div>
                
                <Box sx={{bgcolor: '#fff', height: '100vh', width: '100vw'}}>

                    <div className='containerInternoNotificaAudio'>

                        <div className='InfosChamadaNotificaAudio'>                    
                           <img src={notificacaoAudio} alt="" width="280px"/>
                        </div>

                        <div className='InfosChamadaEncerrada'>                    
                            <Typography className='DesejaEncerrarChamada'>O Anjo {dados.anjo} solicitou uma chamada de áudio com você. Clique no botão abaixo para aceitar:</Typography>
                        </div>
                        
                            
                        
                        <div className='FooterNotification'>

                        <button type='button' className='buttonNotification' onClick={iniciarchamada}>
                                <Iconify icon="eva:phone-fill" className='iconButtonNotification'/>  
                                Aceitar chamada
                            </button>

                            <Typography className='ComoFuncionaFooterNotificaAudio'> 
                            Como funciona?  
                                <Link href="#" color="#3366FF" underline="none">
                                    Clique aqui
                                </Link>     
                            </Typography>
                        </div>

                    </div>
                </Box>
            </Container></>
        )}

            

            </>
    );
}