// ----------------------------------------------------------------------

export function getCoresTemplate(tipoAtendente: any) {
  if (tipoAtendente === 1) {
    /* CONFIGURAÇÃO ANJO */
    return {
      nomeTipoAtendente: 'Anjo',
      corPrincipal: 'corPrincipalAnjo',
      corSecundaria: 'corSecundariaAnjo',
      capaAtendenteDashFirstDesk: 'CapaAtendenteCoverProfileDesktopAnjo',
      chipListaAtendentes: 'chipTipoAtendenteAnjo',
    };
  }

  if (tipoAtendente === 2) {
    /* CONFIGURAÇÃO PSICOLOGO */
    return {
      nomeTipoAtendente: 'Psicólogo(a)',
      corPrincipal: 'corPrincipalPsicologo',
      corSecundaria: 'corSecundariaPsicologo',
      capaAtendenteDashFirstDesk: 'CapaAtendenteCoverProfileDesktopPsicologo',
      chipListaAtendentes: 'chipTipoAtendentePsicologo',
    };
  }

  if (tipoAtendente === 3) {
    /* CONFIGURAÇÃO PSICANALISTA */
    return {
      nomeTipoAtendente: 'Psicanalista',
      corPrincipal: 'corPrincipalPsicanalista',
      corSecundaria: 'corSecundariaPsicanalista',
      capaAtendenteDashFirstDesk: 'CapaAtendenteDashFirstDeskPsicanalista',
      chipListaAtendentes: 'chipTipoAtendentePsicanalista',
    };
  }

  if (tipoAtendente === 4) {
    /* CONFIGURAÇÃO TERAPEUTA */
    return {
      nomeTipoAtendente: 'Terapeuta',
      corPrincipal: 'corPrincipalTerapeuta',
      corSecundaria: 'corSecundariaTerapeuta',
      capaAtendenteDashFirstDesk: 'CapaAtendenteCoverProfileDesktopTerapeuta',
      chipListaAtendentes: 'chipTipoAtendenteTerapeuta',
    };
  }

  if (tipoAtendente === 5) {
    /* CONFIGURAÇÃO COACH */
    return {
      nomeTipoAtendente: 'Coach',
      corPrincipal: 'corPrincipalCoach',
      corSecundaria: 'corSecundariaCoach',
      capaAtendenteDashFirstDesk: 'CapaAtendenteCoverProfileDesktopCoach',
      chipListaAtendentes: 'chipTipoAtendenteCoach',
    };
  }




  return {
    nomeTipoAtendente: 'Anjo',
    corPrincipal: 'corPrincipalAnjo',
      corSecundaria: 'corSecundariaAnjo',
      capaAtendenteDashFirstDesk: 'CapaAtendenteCoverProfileDesktopAnjo',
      chipListaAtendentes: 'chipTipoAtendenteAnjo',
   };
}
