
import {useEffect, useState, useCallback, useMemo} from 'react';
import { Helmet } from 'react-helmet-async';
// sections
import { EditarPerfil } from 'src/sections/@dashboard/EditarPerfil';
import {useParams} from "react-router-dom";
import axios from "../utils/axios";





export default function EditarPerfilPage() {
    const {idAtendente = ''} = useParams();
  const [info, setInfo] = useState<any>({ socialLinks:{facebookLink: '' , instagramLink: '', linkedinLink: '' , twitterLink: '' }  }); 
  const [posts, setPosts] = useState<any>({data:[]});
  const [Atendented, setAtendente] = useState<any>({});



  return (
    <>
      <Helmet>
        <title> Editar Perfil do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

    <EditarPerfil  />
      
    </>
  );
}
