
import { useState, useRef } from 'react';
// @mui

import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper,FormControl,OutlinedInput,InputAdornment,ToggleButtonGroup,ToggleButton, ButtonGroup, InputLabel,MenuItem,Select,Rating,Button, Typography,TextField, CardContent,SelectChangeEvent, Divider, IconButton } from '@mui/material';
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify/Iconify';
import {LoadingButton} from "@mui/lab";
import ProfilePicture from '../img/profile_picture.png';
import axios from "../../../../utils/axios";
import {useSnackbar} from "../../../../components/snackbar";
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';

import './CardDivulgaPacotes.css';

import imgDivulgaPacote from '../img/cardDivulgaPacotes.png'

// utils

// components

// ----------------------------------------------------------------------



export default function CardDivulgaPacotes() {

    const { enqueueSnackbar } = useSnackbar();
    const { copy } = useCopyToClipboard();
const [valor, setvalor] = useState<any>('10');
const [isSubmitSuccessful, setisSubmitSuccessful] = useState<any>(false);


  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newvalor: string,
  ) => {
    setvalor(newvalor);
  };

  const pixcopiaecola = async () => {
      setisSubmitSuccessful(true);
      try {
          const response = await axios.post('/areadoheroi/pixcopiaecola',{
              Valor:valor
          });

          const {dadosresponse} = response.data;
          copy(dadosresponse.codigo);
          enqueueSnackbar(`Copiado com sucesso!`, {variant: 'success'});
          setisSubmitSuccessful(false);

      } catch (error) {
          setisSubmitSuccessful(false);
          enqueueSnackbar(`${error.message}`, {variant: 'error'});
      }
  }


  return (
    <>
        <div className='CardDivulgaPacoteDashboard'>

            <img src={imgDivulgaPacote} width={220} alt='Divulgação Pacotes de Conversas'/>

            <Typography className='TituloCardAzulDashInicialDivulgaPacote'>Agora você pode contratar pacotes de atendimentos!</Typography>
            <Typography className='SubtituloCardAzulDashInicialDivulgaPacote'>Conheça o novo recurso do Eyhe que permite comprar pacotes de conversas com seu atendente preferido e ainda ganhar um desconto!</Typography>


        </div>

    </>
  );
}
