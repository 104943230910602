import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import {Atendetentes} from "../sections/@dashboard/Atendentes";
import {Cadastro} from "../sections/auth/Cadastro";



export default function CadastrePage() {
  return (
    <>
      <Helmet>
        <title>Cadastre-se como Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Cadastro  />
    </>
  );
}
