import {useEffect, useState} from 'react';
import {Outlet, useParams} from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import {useAuthContext} from "../../auth/useAuthContext";
import Notificacoes from "../../sections/@dashboard/Notificacoes/Notificacoes";

// ----------------------------------------------------------------------
type props = {
    socket: any;
};
export default function DashboardLayout({socket}:props) {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [openmodal, setopenmodal] = useState(false);
  const [open2, setOpen2] = useState<any>('');
  const [Dadosnoti, setDadosnoti] = useState<any>('');
    const { user } = useAuthContext();
  const isNavHorizontal = themeLayout === 'horizontal';
    const {tokenConversa = ''} = useParams();
  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };


    useEffect(() => {

       const  url = new URL(window.location.href);
        url.searchParams.delete("code");
        url.searchParams.delete("scope");
        url.searchParams.delete("authuser");
        url.searchParams.delete("prompt");
        window.history.pushState('object or string', 'Title', url);
        socket.emit('entrarusuario', `atendido${  user?.id}`);
        socket.on("connect", () => {
            console.log('usuario relogado');
            socket.emit('entrarusuario', `atendido${  user?.id}`);

            if(tokenConversa){
                console.log('entrou na sala');
                socket.emit('entrarsala', tokenConversa);
            }

        });// client-side




    },[socket, tokenConversa, user?.id]);



    useEffect(() => {

        let timer:any;

        socket.on("notifica", (dados:any) => {
            setDadosnoti(dados);
            setOpen2(dados.tipo);
            setopenmodal(true);
            console.log('sasasa');

        });

    },[socket,user]);





  const handleClose = () => {
    setOpen(false);
  };

  const fecharmodal = () => {
    setOpen2('');
    setopenmodal(false);
  };

    // notificaaudio
    // notificavideo

    const tipo = (
        <>



            { openmodal  ?
                (
                    <Dialog
                        fullScreen
                        scroll='body'
                        open
                    ><Notificacoes fecharmodal={fecharmodal} dados={Dadosnoti} estadoNotifica={open2}/>
                    </Dialog>
                ) : ('')
            }
        </>
    );


  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;


  if (isNavHorizontal) {
    return (
      <>
          {tipo}
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
          {tipo}
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
        {tipo}
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
