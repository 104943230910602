import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import {useEffect} from "react";
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
import { _userList } from '../../../_mock/arrays';
// components
import { useSettingsContext } from '../../../components/settings';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import UserNewEditForm from '../user/UserNewEditForm';
import {useAuthContext} from "../../../auth/useAuthContext";

// ----------------------------------------------------------------------

export default function EditarPerfil() {
  const { themeStretch } = useSettingsContext();
    const { user,getuser } = useAuthContext();
  const { name } = useParams();


    useEffect(() => {

        getuser();

    }, [getuser]);
  return (
    <>
      <Helmet>
        <title> Editar Perfil | Eyhe</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Editar Perfil"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Usuário',
              href: PATH_DASHBOARD.root,
            },
            { name: user?.nome },
          ]}
        />

        <UserNewEditForm isEdit currentUser={user} />
      </Container>
    </>
  );
}
